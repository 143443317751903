import React, { useState, useEffect } from 'react';
import Cards from 'react-credit-cards';
import { Link, useHistory } from 'react-router-dom'

import UIkit from 'uikit';
import MDUIkit from 'md-uikit';

import Cleave from 'cleave.js/react';
import Header from './header_cart';
import Footer from './footer';

import DoneImg from "../../assets/img/thank-you-icon.gif"
import cards from "../../assets/img/cards.JPG"
import PCILogo from "../../assets/img/pci-logo.png"
import verifySSL from "../../assets/img/verify_ssl.png"

import UserActions from '../../providers/user/user.actions';

import 'react-credit-cards/lib/styles.scss';
import Logo from '../../assets/img/main_logo.png'
import { config } from '../../settings.js';

const PaymentScreen = () => {
    const [confirmationDetails, setConfirmationDetails] = useState([]);

    const user = UserActions();
    const history = useHistory();
    useEffect(() => {
        MDUIkit.components();
        if (!user.currentUser) {
            history.push("./");
        }
        window.scrollTo(0,0);
        /* cart.cartItems */
        console.log();
    }, []);
    return (
        <>
            {user.currentUser &&
                <>
                    < div className="uk-container">
                        <div className="uk-padding">

                            <div className="uk-card uk-card-body" align="center">
                                <img src={Logo} width="180px" />
                                <h3 className="md-color-green-500">¡ Pago completado !</h3>
                                <img width='50px' src={DoneImg} alt="" />
                                <h5>{user.currentUser.name+ " "+user.currentUser.lastname}</h5>
                                <h5>S/. {user.currentUser.amount}</h5>
                                <p>Hemos recibido tu pedido.</p>
                                <p>Pronto recibirás un correo a <strong>{user.currentUser.email}</strong> con los siguientes pasos.</p>
                                <Link to="./" className="md-btn md-btn-outlined md-btn-primary md-btn-wave-light">
                                    ir a la pagina principal</Link>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </>
            }
        </>
    );
}

export default PaymentScreen;
