import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import UIkit from 'uikit';
import MDUIkit from 'md-uikit';
import moment from 'moment';
import $ from 'jquery'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';


import Footer from './footer';
import Header from './header';
import BGLogin from '../../assets/img/testimonials_bg.jpg'
import testimonio from '../../assets/img/testimonio.jpg'
import trainer from '../../assets/img/trainer.png'
import fitness_image from '../../assets/img/fitness_image.png'
import web1 from '../../assets/img/web1.jpg'
import web3 from '../../assets/img/web3.jpg'
import web2 from '../../assets/img/web2.jpg'
import fondo_contact from '../../assets/img/fondo_contact.jpg'
import LogoVertical from '../../assets/img/logo_mat_vertical.png'
import image01 from '../../assets/img/01.jpg'
import image02 from '../../assets/img/02.jpg'
import image03 from '../../assets/img/03.jpg'
import team01 from '../../assets/img/MICH2.png'
import team02 from '../../assets/img/MICH1.png'
import team03 from '../../assets/img/MICH3.png'
import ceo_img from '../../assets/img/ceo_img.jpg'

import 'uikit/dist/css/uikit.min.css';
import 'md-uikit/dist/css/md-uikit.css';

import CartActions from '../../providers/cart/cart.actions';
import { config } from '../../settings.js';
import Logo from '../../assets/img/main_logo.png'

const Home = () => {

  const [bannersArray, setBannersArray] = useState([]);
  const [productsArray, setProductsArray] = useState([]);
  const [commingSoonActive, setCommingSoonActive] = useState(false);
  const [newSubscriber, setNewSubscriber] = useState({
    name: "",
    price: "",
    alt_price: "",
    description: "",
    image: ""
  });
  const [today, setToday] = useState([]);

  const cart = CartActions();
  let history = useHistory();
  let fecha_publicacion = "2020-10-24T00:00:00";

  useEffect(() => {
    fetchBanners();
    fetchProducts();
    const todayVal = moment().format("YYYY-MM-DD");
    setToday(todayVal);
    const hoy = moment();
    console.log(hoy);
    if (moment(fecha_publicacion) > hoy) {
      console.log("vale para hoy");
      setCommingSoonActive(true);
    }
  }, [])
  async function fetchBanners() {

    try {
      const response = await fetch(`${config.connectionString}/api/banner_read.php`, {
        method: 'GET',
      });
      if (response.status >= 400 && response.status < 500) {
        UIkit.notification('Information error.');
        throw "Error" + response.status;
      }
      if (response.status >= 500 && response.status < 600) {
        UIkit.notification('Error. Please try later.');
      }
      const res = await response.json();

      setBannersArray(res);
      /* modal.hide(); */
      console.log(res);
    } catch (error) {
      /*  modal.hide(); */
      console.log(error);
    }
  }
  async function fetchProducts() {

    try {
      const response = await fetch(`${config.connectionString}/api/product_read.php`, {
        method: 'POST'
      });
      if (response.status >= 400 && response.status < 500) {
        UIkit.notification('Information error.');
        throw "Error" + response.status;
      }
      if (response.status >= 500 && response.status < 600) {
        UIkit.notification('Error. Please try later.');
      }
      const res = await response.json();
      for (let index = 0; index < res.length; index++) {
        const element = res[index];/* 
        var dom = document.createElement('div');
        dom.innerHTML = element.description; 
        element.description = "casa";*/
      }
      setProductsArray(res);
      /* modal.hide(); */
      console.log(res);
    } catch (error) {
      /*  modal.hide(); */
      console.log(error);
    }
  }
  function renderBanner(banner) {
    var content;
    switch (banner.visible) {
      case '1':
        content = (
          <li>
            <img src={`${config.connectionString}${banner.img}`} alt="" data-uk-cover />
          </li>
        )
        break;
      case '2':
        const hoy = moment();
        if (moment(banner.dateFrom) <= hoy && hoy <= moment(banner.dateTo)) {
          content = (
            <li>
              <img src={`${config.connectionString}${banner.img}`} alt="" data-uk-cover />
            </li>
          )
        }
        break;
      default:
        break;
    }
    return content;
  }
  const handleChange = event => {
    event.preventDefault();
    const { value, name } = event.target;
    setNewSubscriber({ ...newSubscriber, [name]: value });
  }
  const openCart = (item) => (event) => {
    event.preventDefault();

    cart.setList(item);
    history.push("./payment");
  }
  /* $('.buy_button').on('click', function (e) {
    // Abre el formulario con las opciones de Culqi.config
    window.Culqi.open();
    console.log("test");
  }); */
  return (
    <>
      {commingSoonActive ?
        <>
          <a className="uk-navbar-item uk-logo" href="#"><img src={Logo} width="180px" /></a>
          <div className="uk-padding uk-text-center">
            <div><h3 className="md-color-white">Ya falta poco</h3></div>
            <div uk-countdown={`date: ${fecha_publicacion}`}>
              <span class="uk-countdown-number uk-countdown-days"></span>
              <span class="uk-countdown-separator">:</span>
              <span class="uk-countdown-number uk-countdown-hours"></span>
              <span class="uk-countdown-separator">:</span>
              <span class="uk-countdown-number uk-countdown-minutes"></span>
              <span class="uk-countdown-separator">:</span>
              <span class="uk-countdown-number uk-countdown-seconds"></span>
            </div>
          </div>
        </>
        :
        <>
          <Header />
          <div id="home-section" className="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" data-uk-slideshow="ratio: 7:3; animation: push">

            <ul className="uk-slideshow-items">
              {bannersArray.length > 0 && bannersArray.map(item => (
                renderBanner(item)
              ))
              }
            </ul>

            <a className="uk-position-center-left uk-position-small uk-hidden-hover" href="#" data-uk-slidenav-previous uk-slideshow-item="previous"></a>
            <a className="uk-position-center-right uk-position-small uk-hidden-hover" href="#" data-uk-slidenav-next uk-slideshow-item="next"></a>

          </div>
          <div id="inicio-section"></div>
          <div className="uk-section" style={{ backgroundImage: `url(${web2})`, backgroundSize: "cover" }} >
            <div className="uk-container">
              <div className="uk-grid-small" data-uk-grid>
                <div className="uk-width-1-6" align="center">
                  <img src={LogoVertical} width="220px" />
                </div>
                <div className="uk-width-5-6" >
                  <h5 className="uk-padding uk-padding-remove-left">Te contamos de nosotros:</h5>
                  <p>MAT es un studio boutique personalizado; nuestra asesoría se enfoca 100% en tus
                  objetivos personales. Estructuramos planes personalizados pensados en ti para
lograr grandes resultados en menor tiempo.</p>
                  <p>Es una experiencia pero también una ciencia, está comprobado que el
                  entrenamiento de intervalos de alta intensidad genera resultados. Asimismo,
                  complementamos tu programa con nuestro sistema MTS (Mat training system);
                  que consiste en la confusión muscular mediante circuitos metabólico efectivos,
diseñados exclusivamente para “liberar lo extraordinario de ti”.</p>
                  <p>¡Nuestra propuesta de Alta Intensidad en training te ayudara a quemar cientos de
                  calorías, bajar de peso, tonificar tus músculos, potenciar todas tus habilidades
                  físicas y tener el estado atlético más bravo de tu vida!
</p>
                  <p>Ofrecemos un entrenamiento integral para que tu cuerpo pueda desarrollarse en
óptimas condiciones en tu día a día</p>
                </div>

              </div>
            </div>
          </div>
          <div data-uk-parallax="bgy: -200" style={{ backgroundImage: `url(${testimonio})`, backgroundSize: "cover" }} className="uk-section">
            <div className="uk-container">
              <div className="uk-grid-small" data-uk-grid>
                <div className="uk-width-1-1@m " align="center">
                  <h3 className="md-color-white">OBJETIVO DE MAT</h3>
                  <h5 className="md-color-white">Fomentamos la actividad física en comunidad por encima de un cuerpo atlético.</h5>
                </div>
              </div>
            </div>
          </div>
          <div></div>
          <div className="uk-section grey-bg about_section">
            <div className="uk-container ">
              <div className="uk-grid" data-uk-grid>
                <div className="uk-width-1-3@m">
                  <img width="100%" src={ceo_img} alt="" />
                </div>
                <div className="uk-width-2-3@m">
                  <h3 className="purple-text">NUESTRO DIRECTOR Y FUNDADOR</h3>
                  <p>Nuestro CEO Michael Talavera, cuenta cómo desde su inicio siempre quiso
                  promover la actividad física; para él, hacer ejercicio es una forma más de
                  divertirse. A lo largo de los años ha ido capacitándose íntegramente en el deporte
para brindar una excelente calidad de servicio.</p>
                  <h4>“Más allá de estar en forma, es llegar a tener armonía y sentirse bien sin mermar lo
más importante que es la SALUD”.</h4>
                  <p>
                    Nos ayuda y motiva a cumplir nuestros objetivos; nos acompaña a mejorar nuestra
                    calidad de vida a través de la actividad física; nos ayuda a entender que una vida
                    dedicada al deporte es una vida de superación con uno mismo.
</p>
                </div>
              </div>
            </div>
          </div>
          <div id="servicios-section" className="uk-section" style={{ backgroundImage: `url(${web3})`, backgroundSize: "cover" }}>
            <div className="uk-container">
              <h5 className="blue-text uk-padding-remove" align="center">¿Por qué nosotros?</h5>
              <h4 className="purple-text uk-margin-remove-top" align="center">Nuestras razones</h4>
              <div data-uk-slider="autoplay: true">
                <div className="uk-position-relative">
                  <ul className="uk-slider-items uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m">
                    <li>
                      <div align="center" className="uk-padding-small">
                        <img src={image03} alt="" />
                        <h3>ENTRENAMIENTOS PERSONALIZADOS</h3>
                        <p>Porqué apostamos por entrenamientos personalizados individuales y exclusivos.</p>
                      </div>
                    </li>
                    <li>
                      <div align="center" className="uk-padding-small">
                        <img src={image01} alt="" />
                        <h3>GRUPOS REDUCIDOS</h3>
                        <p>Porqué trabajamos con grupos reducidos; donde es importante la mejor atención para crear un ambiente más profesional (el coach estará para ti dirigiendo, corrigiendo y motivando), y crear una comunidad.</p>
                      </div>
                    </li>
                    <li>
                      <div align="center" className="uk-padding-small">
                        <img src={image02} alt="" />
                        <h3>ATENCIÓN PERSONALIZADA</h3>
                        <p>Porqué la atención personalizada a nuestros socios va más allá de los entrenamientos, ofreciéndole asesoramiento wellness, nutricional y médico, con seguimiento por parte del coach.</p>
                      </div>
                    </li>
                  </ul>
                  <a className="uk-position-center-left uk-position-small uk-hidden-hover" href="#" data-uk-slidenav-previous data-uk-slider-item="previous"></a>
                  <a className="uk-position-center-right uk-position-small uk-hidden-hover" href="#" data-uk-slidenav-next data-uk-slider-item="next"></a>


                </div>
              </div>
            </div>
          </div>
          <div></div>
          {/*<div className="uk-section " style={{ backgroundImage: `url(${web2})`, backgroundSize: "cover" }}>
         <div className="uk-container">
          <div className="uk-grid-small" data-uk-grid>
            <div className="uk-width-1-1">
              <h3 align="center" className="purple-text uk-padding uk-padding-remove-top">Servicios Especializados</h3>
            </div>
            <div className="uk-width-1-3@m" align="center">
              <h2><div className="numberCircle">1</div></h2>
              <h4>COACHING PERSONALIZADO GRUPAL</h4>
              <p>Si te gustan las clases grupales, intensidad, divertirte frente a un entrenador motivándote, buena música ¡ESTO ES PAR TI! Te planificamos un programa personalizado acorde a tu estado físico actual y objetivo principal de acuerdo a tus deficiencias
</p>
            </div>
            <div className="uk-width-1-3@m" align="center">
              <h2><div className="numberCircle">2</div></h2>
              <h4>COACHING PERSONALIZADO EN VIVO 1 a 1O</h4>
              <p>Si te gusta tener alguien asesorándote y monitoreándote de cerca totalmente personalizado 1 a 1 por Michael coach ¡ESTO ES PARA TI!</p>
            </div>
            <div className="uk-width-1-3@m" align="center">
              <h2><div className="numberCircle">3</div></h2>
              <h4>MAT KIDS</h4>
              <p>La actividad física es uno de los puntos clave para el desarrollo infantil.
              En MAT KIDS se realizan diversos ejercicios y juegos, todo ello enfocado a mejorar las destrezas motrices del niño.
</p>
              <p><button className="md-btn md-btn-primary ripple-surface" data-uk-toggle="target: #modal-kids"><i className="material-icons">add</i> ver más</button></p>
              <div id="modal-kids" data-uk-modal>
                <div className="uk-modal-dialog uk-margin-auto-vertical">
                  <div className="uk-modal-body uk-grid-small" data-uk-grid>
                    <button className="uk-modal-close-default" type="button" data-uk-close></button>
                    <h4 className="primary-text">BENEFICIOS PARA EL NIÑO A NIVEL FISICO</h4>
                    <ul>
                      <li>Crea consciencia corporal.</li>
                      <li>Corregir la postura del cuerpo.</li>
                      <li>Mejora el desarrollo de la movilidad y estabilidad articular.</li>
                      <li>Incrementa la fuerza.</li>
                      <li>Previene lesiones.</li>
                      <li>Desarrolla la psicomotricidad.</li>
                      <li>Mejora el sistema vestibular.</li>
                      <li>Mejora la lateralidad.</li>
                    </ul>
                    <h4>BENEFICIOS PARA EL NIÑO A NIVEL INTELECTUAL, SOCIAL Y EMOCIONAL</h4>
                    <ul>
                      <li>Mejora la concentración, aumentando la capacidad de atención.</li>
                      <li>Desarrolla la autoestima del niño.</li>
                      <li>Favorece las relaciones sociales (respeto, igualdad, normas, trabajo en grupo).</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div> 
      </div>*/}
          <div id="nosotros-section" className="uk-section " style={{ backgroundImage: `url(${web2})`, backgroundSize: "cover" }}>
            <div className="uk-container">
              <div className="uk-grid-small" data-uk-grid>
                <div className="uk-width-1-1">
                  <h3 align="center" className="purple-text uk-padding uk-padding-remove-top">Nuestro Equipo</h3>
                </div>
                <div className="uk-width-1-3@m" align="center">
                  <img src={team01} alt="" />
                  <h4>MICHAEL TALAVERA </h4>
                  <h6 className="uk-margin-remove-top">CEO Y DIRECTOR DE MAT </h6>
                  <p>Head coach certificado Institute of Human Performance - Coach certificado functional training ( ACTIVE)  - Coach certificado functional training deportivo ( ACTIVE ) - Coach certificado functional training TRX  - Coach certificado TRX nivel 2 - Nutrición deportiva
</p>
                </div>
                <div className="uk-width-1-3@m" align="center">
                  <img src={team02} alt="" />
                  <h4>DR. CHRISTIAN ALVARADO ROSAS</h4>
                  <h6>Medico especialista en Medicina Física y rehabilitación (Hospital Rio Hortega, España).</h6>
                  <p>Subespecializado en medicina regenerativa y rehabilitacion deportiva. Megister en acupuntura y en gerencia en salud.</p>
                </div>
                <div className="uk-width-1-3@m" align="center">
                  <img src={team03} alt="" />
                  <h4>DEYSI ISABEL PUMA QUISPE</h4>
                  <h6>Licenciada en Nutrición Humana - CNP 3688</h6>
                  <p>Licenciada en Nutrición Humana y maestrando en Seguridad Alimentaria y Desarrollo Humano (UNSA), especializada en la Implementación de la Norma ISO 22000 y del Sistema HACCP para Servicios de Alimentación (UNALM). Participación contante en medios de comunicación radial, televisivo y escrito.
</p>

                </div>

              </div>
            </div>
          </div>
          <div style={{ backgroundImage: `url(${BGLogin})` }} className="uk-section">
            <div className="uk-container">
              <div className="uk-grid-small" data-uk-grid>
                <div className="uk-width-1-1@m " align="center">
                  <h5 className="md-color-white">Nuestro sistema MTS el cual consiste en la confusión muscular mediante circuitos que estimulan el rendimiento del cuerpo donde todas nuestras membresías incluyen estructuración de plan de nutrición.</h5>

                </div>
              </div>
            </div>
          </div>
          <div id="planes-section"></div>
          <div className="uk-section">
            <div className="uk-container">
              <div className="uk-width-1-1">
                <h3 align="center" className="purple-text uk-padding uk-padding-remove-top">PROGRAMAS</h3>
              </div>
              <div className="uk-grid-small uk-grid-match" data-uk-grid>
                {productsArray.length > 0 ?
                  productsArray.map(item => (
                    <div className="uk-width-1-3@m">
                      <div className="uk-card cascade-card-narrower">
                        <div className="uk-card-media-top cascade-card-overlay md-bg-white">
                          {item.image != "default" ?
                            <img src={`${config.connectionString}${item.image}`} alt="" />
                            :
                            <div className="gradient_image_header_2" style={{ height: "250" }} />
                          }

                        </div>
                        <div className="cascade-card-content uk-card-body">
                          <h5 className="uk-text-center">{item.name}</h5>
                          {/* <p>Da acceso a visualizar las clases en vivo via zoom o google meet.</p> */}
                          {item.alt_price != "0.00" && item.price != "0.00" ?
                            <>
                              <h5 className="price_match md-color-grey-500 uk-margin-remove-bottom">S/. {item.price}</h5>
                              <h3 className="uk-margin-remove-top">S/. {item.alt_price}</h3>
                            </>
                            :
                            <>
                              {item.price != "0.00" && <h3 className="">S/. {item.price}</h3>}
                            </>
                          }
                          <hr />
                          <div class="uk-text-center" dangerouslySetInnerHTML={{ __html: item.description }}></div>
                          <p align="center">
                            {item.price != "0.00" ?
                              <button onClick={openCart(item)} className=" md-btn md-btn-primary ripple-surface">
                                <i className="material-icons">local_mall</i> COMPRAR
                        </button>
                              :
                              <button className=" md-btn md-btn-primary ripple-surface" disabled>
                                NO DISPONIBLE
                        </button>
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                  :
                  (
                    <h4>Cargando...</h4>
                  )
                }
              </div>
              <div className="uk-grid-small" data-uk-grid>
                <div className="uk-width-1-1">
                  <h4 align="center" >HORARIOS CLASES - HOME PROGRAM</h4>
                </div>
                <div className="uk-width-1-3@m">
                  <table className="uk-table uk-table-striped" border="1">
                    <tr><td className="md-bg-grey-900">
                      <h5 align="center" className=" md-color-white">MAÑANA</h5></td></tr>
                    <tr align="center"><td className="md-bg-grey-800"><h6 className="md-color-white">6:30 AM – 6:15 AM</h6></td></tr>
                    <tr align="center"><td className="md-bg-grey-800"><h6 className="md-color-white">8:00 AM – 8:45 AM</h6></td></tr>
                    <tr align="center"><td className="md-bg-grey-800"><h6 className="md-color-white">10:00 AM – 10:45 AM</h6></td></tr>
                    <tr align="center"><td className="md-bg-grey-800"><h6 className="md-color-white">12:00 PM – 12:45 PM</h6></td></tr>
                  </table>
                </div>
                <div className="uk-width-1-3@m">
                  <table className="uk-table uk-table-striped" >
                    <tr>
                      <td className="md-bg-grey-900">
                        <h5 align="center" className=" md-color-white">TARDE</h5></td>
                    </tr>
                    <tr align="center">
                      <td className="md-bg-grey-800"><h6 className="md-color-white">7:00 PM – 7:45 PM</h6></td>
                    </tr>
                    <tr align="center">
                      <td className="md-bg-grey-800"><h6 className="md-color-white">8:00 PM – 8:45 PM</h6></td>
                    </tr>
                  </table>
                </div>
                <div className="uk-width-1-3@m">

                  <table className="uk-table uk-table-striped">
                    <tr><td className="md-bg-grey-900">
                      <h5 align="center" className=" md-color-white">MAT KIDS</h5></td></tr>
                    <tr align="center"><td className="md-bg-grey-800"><h6 className="md-color-white">4:00PM A 4:45 PM</h6></td></tr>
                  </table>
                </div>
              </div>
              <div className="uk-grid-small" data-uk-grid>
                <div className="uk-width-1-1">
                  <h4 align="center" className="uk-padding-remove">HORARIOS CLASES - PRESENCIAL</h4>
                </div>
                <div className="uk-width-1-3@m">
                  <table className="uk-table uk-table-striped" border="1">
                    <tr><td className="md-bg-grey-900">
                      <h5 align="center" className=" md-color-white">MAÑANA</h5></td></tr>
                    <tr align="center"><td className="md-bg-grey-800"><h6 className="md-color-white">6:15 AM – 7:00 AM</h6></td></tr>
                    <tr align="center"><td className="md-bg-grey-800"><h6 className="md-color-white">8:00 AM – 8:45 AM</h6></td></tr>
                    <tr align="center"><td className="md-bg-grey-800"><h6 className="md-color-white">10:00 AM – 10:45 AM</h6></td></tr>
                    <tr align="center"><td className="md-bg-grey-800"><h6 className="md-color-white">11:00 PM – 12:00 PM</h6></td></tr>
                  </table>
                </div>
                <div className="uk-width-1-3@m">
                  <table className="uk-table uk-table-striped" >
                    <tr>
                      <td className="md-bg-grey-900">
                        <h5 align="center" className=" md-color-white">TARDE</h5></td>
                    </tr>
                    <tr align="center">
                      <td className="md-bg-grey-800"><h6 className="md-color-white">5:00 PM – 5:45 PM</h6></td>
                    </tr>
                    <tr align="center">
                      <td className="md-bg-grey-800"><h6 className="md-color-white">6:00 PM – 6:45 PM</h6></td>
                    </tr>
                    <tr align="center">
                      <td className="md-bg-grey-800"><h6 className="md-color-white">7:00 PM – 7:45 PM</h6></td>
                    </tr>
                    <tr align="center">
                      <td className="md-bg-grey-800"><h6 className="md-color-white">8:00 PM – 8:45 PM</h6></td>
                    </tr>
                  </table>
                </div>
                {/* <div className="uk-width-1-3@m">

              <table className="uk-table uk-table-striped">
                <tr><td className="md-bg-grey-900">
                  <h5 align="center" className=" md-color-white">MAT KIDS</h5></td></tr>
                <tr align="center"><td className="md-bg-grey-800"><h6 className="md-color-white">4:00PM A 4:45 PM</h6></td></tr>
              </table>
            </div> */}
              </div>
            </div>
          </div>
          {/* <div data-uk-parallax="bgy: -200" style={{ backgroundImage: `url(${testimonio})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }} className="uk-section">
        <div className="uk-container">
          <h3 align="center" className="purple-text">Testimonios</h3>
          <div data-uk-slider="autoplay: true">
            <div className="uk-position-relative">
              <div className="uk-slider-container">
                <ul className="uk-slider-items uk-child-width-1-1">
                  <li>
                    <div align="center" className="uk-padding-small">
                      <h4 className="md-color-white">Success isn’t really that difficult. There is a significant portion of the population here in North America, that actually want and need success to be hard! Why?</h4>
                      <p className="blue-text">Jean Vera</p>
                      <p className="md-color-white">Alumno</p>
                    </div>
                  </li>
                  <li>
                    <div align="center" className="uk-padding-small">
                      <h4 className="md-color-white">Success isn’t really that difficult. There is a significant portion of the population here in North America, that actually want and need success to be hard! Why?</h4>
                      <p className="blue-text">Jean Vera</p>
                      <p className="md-color-white">Alumno</p>
                    </div>
                  </li>
                  <li>
                    <div align="center" className="uk-padding-small">
                      <h4 className="md-color-white">Success isn’t really that difficult. There is a significant portion of the population here in North America, that actually want and need success to be hard! Why?</h4>
                      <p className="blue-text">Jean Vera</p>
                      <p className="md-color-white">Alumno</p>
                    </div>
                  </li>
                </ul>
              </div>

            </div>
          </div>
        </div>
      </div> */}
          <div className="uk-section" style={{ backgroundImage: `url(${fondo_contact})`, backgroundSize: "cover" }}>
            <div className="uk-container">
              <div className="uk-grid-small" data-uk-grid align="center">
                <div className="uk-width-1-2@m">
                  <img src={fitness_image} alt="" width="80%" />
                </div>
                <div className="uk-width-1-2@m">
                  <h2 className="md-color-white">No dejes para mañana, <span className="purple-text"> lo que puedes hacer hoy.</span></h2>

                  <div className="uk-card">
                    <div className="uk-card-body">
                      <div className="uk-grid-small" data-uk-grid >
                        <p>Estamos para resolver tus dudas, dejanos un mensaje</p>
                        <div className="uk-width-1-1">
                          <div className="md-input-wrapper ">
                            <label>Nombre</label>
                            <input type="text" name='name' className="md-input" onChange={handleChange} required value={newSubscriber.name} />
                          </div>
                        </div>
                        <div className="uk-width-1-2">
                          <div className="md-input-wrapper  ">
                            <label>Email</label>
                            <input type="email" name='email' className="md-input" onChange={handleChange} required value={newSubscriber.email} />
                          </div>
                        </div>
                        <div className="uk-width-1-2">
                          <div className="md-input-wrapper  ">
                            <label>Telefono</label>
                            <input type="email" name='phone' className="md-input" onChange={handleChange} required value={newSubscriber.phone} />
                          </div>
                        </div>
                        <div className="uk-width-1-1">
                          <div className="uk-form-row md-input-wrapper md-input-textarea">
                            <label>Mensaje</label>
                            <textarea className="md-input" name='message' onChange={handleChange} value={newSubscriber.message}></textarea>
                          </div>
                        </div>
                        <div className="uk-width-1-1">
                          <p><button className="md-btn md-btn-secondary">Enviar Mensaje</button></p>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <Footer />
          <div className="md-fab-wrapper">
            <a data-uk-tooltip="Estamos en línea" href="https://wa.me/51959315818/?text=Hola,%20te%20escribo%20desde%20la%20web" className="md-fab md-fab-primary ripple-surface"><i className="fab fa-whatsapp"></i></a>
          </div>
        </>
      }
    </>
  );
}

export default Home;
