import React, { useState, useEffect } from 'react';

import UIkit from 'uikit';
import axios from 'axios';
import MDUIkit from 'md-uikit';
import Slim from '../../assets/slim/slim.react';
import { useParams, useHistory } from "react-router-dom";


import UserActions from '../../providers/user/user.actions';
import {config} from '../../settings.js';

const Products = () => {
    const [clientInfo, setClientInfo] = useState([]);

    const user = UserActions();
    let history = useHistory();
    let { id } = useParams();

    const handleChange = event => {
        event.preventDefault();
        const { value, name } = event.target;
        console.log(name);
        console.log(name + " " + value);
        setClientInfo({ ...clientInfo, [name]: value });
    }

    async function fetchClientInfo() {

        try {
            const req = {
                "id": id,
                "jwt": user.currentUser.token
            };
            const response = await fetch(`${config.connectionString}/api/person_read_by_id.php`, {
                method: 'POST',
                body: JSON.stringify(req)
            });
            if (response.status >= 400 && response.status < 500) {
                UIkit.notification('Information error.');
                throw "Error" + response.status;
            }
            if (response.status >= 500 && response.status < 600) {
                UIkit.notification('Error. Please try later.');
            }
            const res = await response.json();

            setClientInfo(res);
            /* modal.hide(); */
            console.log(res);
        } catch (error) {
            /*  modal.hide(); */
            console.log(error);
        }
    }
    useEffect(() => {
        MDUIkit.components();
        console.log("banner : ");
        console.log("banner : " + id);
        fetchClientInfo();
    }, []);

    async function sendInfo(newObj) {
        try {
            const req = {
                "id": id,
                "dni": clientInfo.dni,
                "firstname": clientInfo.firstname,
                "lastname": clientInfo.lastname,
                "email": clientInfo.email,
                "phone": clientInfo.phone,
                "dob": clientInfo.dob,
                "jwt": user.currentUser.token
            };
            console.log("sendInfo");
            console.log(JSON.stringify(req));
            var modal = UIkit.modal.dialog('<div className=\'uk-modal-body\'><p>Cargando..</p></div>', { 'bgClose': false, 'escClose': false });

            const response = await fetch(`${config.connectionString}/api/person_update.php`, {
                method: 'POST',
                body: JSON.stringify(req)
            });
            if (response.status >= 400 && response.status < 500) {
                UIkit.notification('Information error.');
                modal.hide();
                throw "Error" + response.status;
            }
            if (response.status >= 500 && response.status < 600) {
                modal.hide();
                UIkit.notification('Error. Please try later.');
            }
            const res = await response.json();
            modal.hide();
            console.log(res);
            UIkit.notification("Información actualizada!", "success");
        } catch (error) {
            modal.hide();
            console.log(error);
        }
    }
    const deleteClient = (e) => {
        e.preventDefault();

        if (window.confirm('Estas Seguro?')) {
            sendDelete();
        }
    }
    async function sendDelete() {
        try {
            const req = {
                "id": id,
                "jwt": user.currentUser.token
            };
            console.log("sendInfo");
            console.log(JSON.stringify(req));
            var modal = UIkit.modal.dialog('<div className=\'uk-modal-body\'><p>Cargando..</p></div>', { 'bgClose': false, 'escClose': false });

            const response = await fetch(`${config.connectionString}/api/person_delete.php`, {
                method: 'POST',
                body: JSON.stringify(req)
            });
            if (response.status >= 400 && response.status < 500) {
                UIkit.notification('Information error.');
                //modal.hide();
                throw "Error" + response.status;
            }
            if (response.status >= 500 && response.status < 600) {
                //modal.hide();
                UIkit.notification('Error. Please try later.');
            }
            const res = await response.json();
            history.push('../clients');
            modal.hide();
            console.log(res);
            UIkit.notification("Información actualizada!", "success");
        } catch (error) {
            modal.hide();
            console.log(error);
        }
    }
    const onClickUpdatePerson = () => {
        let flagWhites = true;
        let flagGeneral = true;
        if (clientInfo.firstname === "" || clientInfo.lastname === "") {
            flagWhites = false;
        }
        if (!flagWhites) {
            flagGeneral = false;
            UIkit.notification('Complete al menos Nombres y Apellidos.', 'danger');
        }
        if (flagGeneral) {
            sendInfo();
        }
    }
    return (
        <>
        <div className="content-before"></div>
            <h4 className="uk-margin-remove-top md-color-white">Ver Cliente</h4>
            {clientInfo.dni ?
                <div className="uk-card uk-card-body">
                    <div className=" uk-grid-small" data-uk-grid>
                        <div class="uk-width-1-3@m">
                            {clientInfo.profileImg &&
                                <img src={`${config.connectionString}${clientInfo.profileImg}`} />
                            }
                        </div>
                        <div class="uk-width-2-3@m">
                            <div className=" uk-grid-small" data-uk-grid>
                                <div class="uk-width-1-1">
                                    <div class="md-input-wrapper md-input-wrapper-outlined md-input-filled">
                                        <label>DNI</label>
                                        <input readOnly type="text" name='dni' class="md-input" onChange={handleChange} required value={clientInfo.dni} />
                                    </div>
                                </div>
                                <div class="uk-width-1-1">
                                    <div class="md-input-wrapper md-input-wrapper-outlined md-input-filled">
                                        <label>Nombres</label>
                                        <input readOnly type="text" name='firstname' class="md-input" onChange={handleChange} required value={clientInfo.firstname} />
                                    </div>
                                </div>
                                <div class="uk-width-1-1">
                                    <div class="md-input-wrapper md-input-wrapper-outlined md-input-filled ">
                                        <label>Apellidos</label>
                                        <input readOnly type="text" name='lastname' class="md-input" onChange={handleChange} required value={clientInfo.lastname} />
                                    </div>
                                </div>
                                <div class="uk-width-1-1">
                                    <div class="md-input-wrapper md-input-wrapper-outlined md-input-filled ">
                                        <label>Email</label>
                                        <input readOnly type="email" name='email' class="md-input" onChange={handleChange} required value={clientInfo.email} />
                                    </div>
                                </div>
                                <div class="uk-width-1-1">
                                    <div class="md-input-wrapper md-input-wrapper-outlined md-input-filled ">
                                        <label>Telefono</label>
                                        <input readOnly type="number" name='phone' class="md-input" onChange={handleChange} required value={clientInfo.phone} />
                                    </div>
                                </div>
                                <div class="uk-width-1-1">
                                    <div class="md-input-wrapper md-input-wrapper-outlined md-input-filled ">
                                        <label>Fecha de Nacimiento</label>
                                        <input readOnly type="date" name='dob' class="md-input" onChange={handleChange} required value={clientInfo.dob} />
                                    </div>
                                </div>
                                <div className="uk-width-1-2">
                                    <div className="md-input-wrapper md-input-wrapper-outlined md-input-filled ">
                                        <label>Talla (CM)</label>
                                        <input readOnly type="text" name='size' className="md-input" onChange={handleChange} required value={clientInfo.size} />
                                    </div>
                                </div>
                                <div className="uk-width-1-2">
                                    <div className="md-input-wrapper md-input-wrapper-outlined md-input-filled ">
                                        <label>Peso en ayunas (KG)</label>
                                        <input readOnly type="text" name='weight' className="md-input" onChange={handleChange} required value={clientInfo.weight} />
                                    </div>
                                </div>
                                <div className="uk-width-1-1">
                                    <div className="uk-form-row md-input-wrapper md-input-textarea md-input-filled ">
                                        <label>Actividad Fisica Actual</label>
                                        <textarea readOnly className="md-input" name='activity' onChange={handleChange} required value={clientInfo.activity}></textarea>
                                    </div>
                                </div>
                                <div className="uk-width-1-1">
                                    <div className="uk-form-row md-input-wrapper md-input-textarea md-input-filled ">
                                        <label>¿Como es tu día a día?</label>
                                        <textarea readOnly className="md-input" name='fullday' onChange={handleChange} required value={clientInfo.fullday}></textarea>
                                    </div>
                                </div>
                                <div className="uk-width-1-1">
                                    <div className="uk-form-row md-input-wrapper md-input-textarea md-input-filled ">
                                        <label>¿Que alimentos te gustaría incluir en tu dieta?</label>
                                        <textarea readOnly className="md-input" name='diet_yes' onChange={handleChange} required value={clientInfo.diet_yes}></textarea>
                                    </div>
                                </div>

                                <div className="uk-width-1-1">
                                    <div className="uk-form-row md-input-wrapper md-input-textarea md-input-filled ">
                                        <label>¿Que alimentos NO te gustaría incluir en tu dieta?</label>
                                        <textarea readOnly className="md-input" name='diet_no' onChange={handleChange} required value={clientInfo.diet_no}></textarea>
                                    </div>
                                </div>
                                {/* <div className="uk-width-1-1" >
                                    <p align="right">
                                        <button onClick={deleteClient} className="md-btn md-color-white md-bg-red-800">Eliminar Cliente</button>
                                        <button onClick={onClickUpdatePerson} className="md-btn md-color-white md-bg-purple-800">Guardar Información</button>
                                    </p>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                : <p>Cargando...</p>
            }
        </>
    );
}
export default Products;