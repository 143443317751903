import React, { useState, useEffect } from 'react';

import UIkit from 'uikit';
import MDUIkit from 'md-uikit';

import MaterialDataTable from '../../../components/datatable.component';


import UserActions from '../../../providers/user/user.actions';
import {config,settings} from '../../../settings.js';

const Products = () => {
    const [newProductInfo, setNewProductInfo] = useState({
        name: "",
        price: "",
        alt_price: "",
        description: "",
        image: ""
    });
    const [productsArray, setProductsArray] = useState([]);

    const user = UserActions();
    const handleChange = event => {
        event.preventDefault();
        const { value, name } = event.target;
        setNewProductInfo({ ...newProductInfo, [name]: value });

    }
    async function fetchProducts() {
        
        try {
            const response = await fetch(`${config.connectionString}/api/product_read.php`, {
                method: 'POST'
            });
            if (response.status >= 400 && response.status < 500) {
                UIkit.notification('Information error.');
                throw "Error" + response.status;
            }
            if (response.status >= 500 && response.status < 600) {
                UIkit.notification('Error. Please try later.');
            }
            const res = await response.json();

            setProductsArray(res);
            /* modal.hide(); */
            console.log(res);
        } catch (error) {
           /*  modal.hide(); */
            console.log(error);
        }
    }
    useEffect(() => {
        MDUIkit.components();
        fetchProducts();
    }, []);

    async function sendInfo() {
        try {
            const req = {
                "name": newProductInfo.name,
                "price": newProductInfo.price,
                "alt_price": newProductInfo.alt_price,
                "description": newProductInfo.description,
                "jwt": user.currentUser.token
            };
            console.log("sendInfo");
            console.log(JSON.stringify(req));
            var modal = UIkit.modal.dialog('<div class=\'uk-modal-body\'><p>Cargando..</p></div>', { 'bgClose': false, 'escClose': false });

            const response = await fetch(`${config.connectionString}/api/product_insert.php`, {
                method: 'POST',
                body: JSON.stringify(req)
            });
            if (response.status >= 400 && response.status < 500) {
                UIkit.notification('Information error.');
                //modal.hide();
                throw "Error" + response.status;
            }
            if (response.status >= 500 && response.status < 600) {
                //modal.hide();
                UIkit.notification('Error. Please try later.');
            }
            const res = await response.json();
            modal.hide();
            console.log(res);
            UIkit.modal("#modal-new").hide();
            window.location.reload();
            /* fetchProducts(); */
        } catch (error) {
            //modal.hide();
            UIkit.modal("#modal-new").show();
            console.log(error);
        }
    }
    const onClickCreateProduct = () => {
        sendInfo();
    }
    return (
        <>
        <div className={`content-before`}></div>
            <div data-uk-grid>
                <div className="uk-width-1-1@m" >
                        <p className="md-color-white">Presione (+) para crear un producto.</p>
                        {productsArray.length > 0 ? (
                            < MaterialDataTable tableRowsArray={productsArray} />
                        ) : (
                                <p>No hay data.</p>
                            )}
                </div>
            </div>
            <div id="modal-new" data-uk-modal>
                <div className="uk-modal-dialog uk-margin-auto-vertical">
                    <div className="uk-modal-header">
                        <h2 className="uk-modal-title">Nuevo Evento</h2>
                    </div>
                    <div className="uk-modal-body uk-grid-small" data-uk-grid>
                        <div class="uk-width-1-1">
                            <div class="md-input-wrapper md-input-wrapper-outlined">
                                <label>Nombre</label>
                                <input type="text" name='name' class="md-input" onChange={handleChange} required value={newProductInfo.name} />
                            </div>
                        </div>
                        <div class="uk-width-1-1">
                            <div class="md-input-wrapper md-input-wrapper-outlined ">
                                <label>Precio</label>
                                <input type="text" name='price' class="md-input" onChange={handleChange} required value={newProductInfo.price} />
                            </div>
                        </div>
                        <div class="uk-width-1-1">
                            <div class="md-input-wrapper md-input-wrapper-outlined ">
                                <label>Precio Especial</label>
                                <input type="text" name='alt_price' class="md-input" onChange={handleChange} required value={newProductInfo.alt_price} />
                            </div>
                        </div>
                        <div class="uk-width-1-1">
                            <div class="uk-form-row md-input-wrapper md-input-textarea">
                                <label>Descripción</label>
                                <textarea class="md-input" name='description' onChange={handleChange} value={newProductInfo.description}></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="uk-modal-footer uk-text-right">
                        <button class="md-btn md-btn-text uk-modal-close" type="button">Cancelar</button>
                        <button onClick={onClickCreateProduct} className="md-btn md-color-white md-btn-primary">Crear Evento</button>
                    </div>
                </div>
            </div>
            <div class="md-fab-wrapper">
                <a data-uk-toggle="target: #modal-new" class="md-fab md-fab-primary md-fab-wave-light" href="javascript:void(0)"><i class="material-icons">add</i></a>
            </div>
        </>
    );
}
export default Products;