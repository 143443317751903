import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import Logo from '../../assets/img/main_logo.png'

const Header = () => {
    return (
        <div>
            <div data-uk-sticky >
                <nav className="uk-navbar-container uk-navbar md-bg-white landing-banner" uk-navbar="mode: click;">
                    <div className="uk-navbar-left">
                        <div className=" uk-hidden@s">
                            <a className="uk-navbar-toggle" uk-toggle="target: #offcanvas-overlay">
                                <i className="material-icons md-icon">menu</i>
                            </a>
                            {/* <div data-uk-dropdown="animation:uk-animation-slide-top-small; duration: 200">
                                <ul class="uk-nav uk-navbar-dropdown-nav">
                                    <li><a href="./#top" data-uk-scroll>Inicio</a></li>
                                    <li><a href="./#servicios-section" data-uk-scroll>Servicios</a></li>
                                    <li><a href="./#nosotros-section" data-uk-scroll>Nosotros</a></li>
                                    <li><a href="./#planes-section" data-uk-scroll>Planes</a></li>
                                    <li><Link to="./preguntas">Preguntas</Link></li>
                                </ul>
                            </div> */}
                        </div>
                        <div className=" uk-visible@s">
                            <a className="uk-navbar-item uk-logo" href="#"><img src={Logo} width="180px" /></a>
                        </div>
                    </div>
                    <div className="uk-navbar-center uk-hidden@s">
                        <a className="uk-navbar-item uk-logo" href="#"><img src={Logo} width="180px" /></a>
                    </div>
                    <div className="uk-navbar-right uk-margin-right uk-visible@s">
                        <ul className="uk-navbar-nav ">
                            <li className="text-on-app-bar"><Link smooth to="./#inicio-section">Inicio</Link></li>
                            <li><Link smooth to="./#servicios-section">Servicios</Link></li>
                            <li><Link smooth to="./#nosotros-section">Nosotros</Link></li>
                            <li><Link smooth to="./#planes-section">Programas</Link></li>
                            <li><Link to="./preguntas">Preguntas</Link></li>
                        </ul>
                    </div>

                </nav>
                <div class="uk-navbar-dropbar"></div>
            </div>

        </div>
    );
}

export default Header;
