import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";

import UIkit from 'uikit';
import MDUIkit from 'md-uikit';
import axios from 'axios';
import CKEditor from '@ckeditor/ckeditor5-react';
import Slim from '../../assets/slim/slim.react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';



import UserActions from '../../providers/user/user.actions';
import { config } from '../../settings.js';

const Products = () => {
    const [productInfo, setProductInfo] = useState([]);
    const [selectedFile, setSelectedFile] = useState([]);
    const [showSlim, setShowSlim] = useState(false);

    const user = UserActions();
    let history = useHistory();
    let { id } = useParams();



    async function fetchproductInfo() {

        try {
            const req = {
                "productId": id,
                "jwt": user.currentUser.token
            };
            console.log(req);
            const response = await fetch(`${config.connectionString}/api/product_read_by_id.php`, {
                method: 'POST',
                body: JSON.stringify(req)
            });
            if (response.status >= 400 && response.status < 500) {
                UIkit.notification('Information error.');
                throw "Error" + response.status;
            }
            if (response.status >= 500 && response.status < 600) {
                UIkit.notification('Error. Please try later.');
            }
            const res = await response.json();

            setProductInfo(res);
            /* modal.hide(); */
            console.log(res);
        } catch (error) {
            /*  modal.hide(); */
            console.log(error);
        }
    }
    useEffect(() => {
        MDUIkit.components();
        console.log("banner : ");
        console.log("banner : " + id);
        fetchproductInfo();
    }, []);

    async function sendInfo(newObj) {
        try {
            const req = {
                "productId": id,
                "name": newObj.name,
                "price": newObj.price,
                "alt_price": newObj.alt_price,
                "description": newObj.description,
                "image": newObj.image,
                "jwt": user.currentUser.token
            };
            console.log("sendInfo");
            console.log(JSON.stringify(req));
            var modal = UIkit.modal.dialog('<div className=\'uk-modal-body\'><p>Cargando..</p></div>', { 'bgClose': false, 'escClose': false });

            const response = await fetch(`${config.connectionString}/api/product_update.php`, {
                method: 'POST',
                body: JSON.stringify(req)
            });
            if (response.status >= 400 && response.status < 500) {
                UIkit.notification('Information error.');
                modal.hide();
                throw "Error" + response.status;
            }
            if (response.status >= 500 && response.status < 600) {
                modal.hide();
                UIkit.notification('Error. Please try later.');
            }
            const res = await response.json();
            modal.hide();
            console.log(res);
            UIkit.notification("Información actualizada!", "success");
        } catch (error) {
            modal.hide();
            console.log(error);
        }
    }
    const deleteClient = (e) => {
        e.preventDefault();

        if (window.confirm('Estas Seguro?')) {
            sendDelete();
        }
    }
    async function sendDelete() {
        try {
            const req = {
                "productId": id,
                "jwt": user.currentUser.token
            };
            console.log("sendDelete");
            console.log(JSON.stringify(req));
            var modal = UIkit.modal.dialog('<div className=\'uk-modal-body\'><p>Cargando..</p></div>', { 'bgClose': false, 'escClose': false });

            const response = await fetch(`${config.connectionString}/api/product_delete.php`, {
                method: 'POST',
                body: JSON.stringify(req)
            });
            if (response.status >= 400 && response.status < 500) {
                UIkit.notification('Information error.');
                //modal.hide();
                throw "Error" + response.status;
            }
            if (response.status >= 500 && response.status < 600) {
                //modal.hide();
                UIkit.notification('Error. Please try later.');
            }
            const res = await response.json();
            history.push('../packages/packages');
            modal.hide();
            console.log(res);
            UIkit.notification("Información actualizada!", "success");
        } catch (error) {
            modal.hide();
            console.log(error);
        }
    }
    const onClickUpdateProduct = () => {
        let flagWhites = true;
        let flagGeneral = true;
        if (productInfo.price === "" || productInfo.title === "") {
            flagWhites = false;
        }
        if (!flagWhites) {
            flagGeneral = false;
            UIkit.notification('Complete toda la información requerida.', 'danger');
        }
        if (flagGeneral) {
            if (showSlim) {
                uploadHandler();
            } else {
                sendInfo(productInfo);
            }
        }
    }

    const handleChange = event => {
        event.preventDefault();
        const { value, name } = event.target;
        console.log(name);
        console.log(name + " " + value);
        setProductInfo({ ...productInfo, [name]: value });
    }
    const uploadHandler = () => {
        const formData = new FormData();

        console.log("Uploading file...");
        console.log(selectedFile);
        let fileName = selectedFile.name;
        let fileContent = selectedFile.file;
        formData.append('code', "123");
        formData.append(
            'myFile_0',
            fileContent,
            fileName
        );
        axios.post(`${config.connectionString}/api/script_insertImage.php`, formData, {
            onUploadProgress: progressEvent => {
                console.log(progressEvent.loaded / progressEvent.total)
            }
        })
            .then(response => {
                console.log(response);
                const newObj = { ...productInfo };
                newObj.image = response.data.url;
                //setBannerInfo({ ...bannerInfo, img: response.data.url });
                
                sendInfo(newObj);

            }).catch(function (error) {
                console.log(error);
            });
    }
    const didConfirmSlim = (data) => {
        // slim instance reference
        console.log(data);
        console.log(data.output.image);
        var canvas = data.output.image;
        var fileName = data.input.name;
        canvas.toBlob(function (blob) {
            setSelectedFile({ file: blob, name: fileName });
        });
    }
    return (
        <>
            <div className="content-before"></div>
            <span>
                <h4 className="md-color-white">
                    <a onClick={() => history.goBack()} >
                        <i className="material-icons md-color-white md-icon">keyboard_arrow_left</i></a>
                Editar Paquete</h4>
            </span>
            {productInfo.name ?
                <div className="uk-card uk-card-body">
                    <div className=" uk-grid-small" data-uk-grid>
                        <div class="uk-width-1-1">
                            <div class="md-input-wrapper md-input-filled">
                                <label>Título</label>
                                <input type="text" name='name' class="md-input" onChange={handleChange} required value={productInfo.name} />
                            </div>
                        </div>
                        <div className="uk-width-1-2@m">
                            {showSlim ?
                                <>
                                    <p>
                                        <Slim
                                            ratio="1:1"
                                            /* minSize={{ width: 600, height: 400 }} */
                                            label="Haga click para subir una imagen"
                                            /* size="200,150" */
                                            instantEdit="true"
                                            didConfirm={didConfirmSlim} >
                                            <input type="file" name="foo" />
                                        </Slim>
                                    </p>

                                    <button className="md-btn md-btn-text md-btn-primary ripple-surface ripple-surface--primary" onClick={() => setShowSlim(false)}>Cancelar</button>

                                </> :
                                <>
                                    <p><img src={`${config.connectionString}${productInfo.image}`} alt="" /></p>
                                    <button className="md-btn md-btn-text md-btn-primary ripple-surface ripple-surface--primary" onClick={() => setShowSlim(true)}>Cambiar imagen</button>
                                </>
                            }
                        </div>
                        <div class="uk-width-1-1">
                            <div class="md-input-wrapper md-input-filled ">
                                <label>Precio</label>
                                <input type="text" name='price' class="md-input" onChange={handleChange} required value={productInfo.price} />
                            </div>
                        </div>
                        <div class="uk-width-1-1">
                            <div class="md-input-wrapper md-input-filled ">
                                <label>Precio Especial</label>
                                <input type="text" name='alt_price' class="md-input" onChange={handleChange} required value={productInfo.alt_price} />
                            </div>
                        </div>
                        {/* <div class="uk-width-1-1">
                            <div class="uk-form-row md-input-wrapper md-input-textarea md-input-filled">
                                <label>Descripción</label>
                                <textarea class="md-input" name='description' onChange={handleChange} value={productInfo.description}></textarea>
                            </div>
                        </div> */}
                        <div className="uk-width-1-1">
                            <CKEditor
                                editor={ClassicEditor}
                                data={productInfo.description}
                                onInit={editor => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log('Editor is ready to use!', editor);
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setProductInfo({ ...productInfo, description: data });
                                    console.log({ event, editor, data });
                                }}
                                onBlur={(event, editor) => {
                                    console.log('Blur.', editor);
                                }}
                                onFocus={(event, editor) => {
                                    console.log('Focus.', editor);
                                }}
                            />
                        </div>
                        <div className="uk-width-1-1" >
                            <p align="right">
                                <button onClick={deleteClient} className="md-btn md-color-white md-bg-red-800">Eliminar Plan</button>
                                <button onClick={onClickUpdateProduct} className="md-btn md-btn-primary ripple-surface">Guardar Información</button>
                            </p>
                        </div>
                    </div>
                </div>
                : <p>Cargando...</p>
            }
        </>
    );
}
export default Products;