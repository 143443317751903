import React, { useState, useEffect } from 'react';

import { Link } from "react-router-dom";

import UIkit from 'uikit';
import MDUIkit from 'md-uikit';
/* import 'md-uikit/dist/css/md-uikit.css'; */

const Table = ({ tableRowsArray }) => {
    const [tableHeaders, setTableHeaders] = useState(
        [
            { title: "Nombre", data: "name" },
            { title: "price", data: "price" },
            { title: "Precio Oferta", data: "alt_price" }
        ]
    )

    const [tableRows, setTableRows] = useState([]);
    const [order, setOrder] = useState([]);

    useEffect(() => {
        MDUIkit.components();
    }, [])
    useEffect(() => {
        setTableRows(tableRowsArray);
    }, [tableRowsArray]);

    const orderBy = (label) => event => {
        event.preventDefault();
        const temp_order = { ...order }
        let data_title = tableHeaders.find(item => item.title == label);
        console.log('order by ' + label + ' founded ' + data_title);
        let newObj;
        if (temp_order.label == label) {
            if (temp_order.type == "ASC") {
                newObj = tableRows.sort(compareValues(data_title.data, 'desc'));
                temp_order.type = "DESC";
            } else {
                newObj = tableRows.sort(compareValues(data_title.data));
                temp_order.type = "ASC";
            }
        } else {
            newObj = tableRows.sort(compareValues(data_title.data));
            temp_order.label = label;
        }

        setTableRows(newObj);
        setOrder(temp_order);
    }
    const filterTable = event => {
        const { value } = event.target;
        let filteredData = [...tableRowsArray];
        if (value) {
            for (let j = 0; j < tableRowsArray.length; j++) {
                const element = tableRowsArray[j];
                let bool_exist = false;
                for (let index = 0; index < tableHeaders.length; index++) {
                    const element_inner = tableHeaders[index];
                    if (element[element_inner.data].toLowerCase().includes(value.toLowerCase())) {
                        console.log(element[element_inner.data]);
                        bool_exist = true;
                    }
                }
                if (!bool_exist) {
                    console.log("eliminado " + element.title);
                    filteredData = filteredData.filter(item => item.productId !== element.productId);
                }
                /* let temp = tableRows.filter(row =>
                    row[element.data].toLowerCase().includes(value.toLowerCase())
                );
                filteredData = filteredData.concat(temp); */
            }
        }
        else {
            filteredData = tableRowsArray;
            console.log('else')
        }
        console.log(filteredData)
        setTableRows(filteredData);
    }
    function compareValues(key, order = 'asc') {
        return function innerSort(a, b) {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                // property doesn't exist on either object
                return 0;
            }
            const varA = (typeof a[key] === 'string')
                ? a[key].toUpperCase() : a[key];
            const varB = (typeof b[key] === 'string')
                ? b[key].toUpperCase() : b[key];

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (order === 'desc') ? (comparison * -1) : comparison
            );
        };
    }
    console.log("tableHeaders.length");
    console.log(tableHeaders[0]);
    return (
        <>
            <div className="uk-card">
                <div class="uk-grid-small" data-uk-grid>
                    <div class="uk-width-1-2@s"></div>
                    <div class="uk-width-1-2@s">
                        <div className="uk-padding-small">
                            <div class="md-input-wrapper md-input-wrapper-shaped md-input-wrapper-with-trailing-icon md-input-wrapper-outlined">
                                <span class="md-input-icon"><i class="material-icons">search</i></span>
                                <label>Buscar:</label>
                                <input type="text" class="md-input" onChange={filterTable} />
                            </div>
                        </div>
                    </div>
                    <div class="uk-width-1-1">
                        <div className='uk-overflow-auto'>
                            <table className='uk-table uk-table-hover'>
                                <thead >
                                    <th></th>
                                    <th><span className="uk-text-bold">Nro</span></th>
                                    {tableHeaders && tableHeaders.map(item => (
                                        <th>
                                            <span className="datatable-header uk-text-bold"
                                                onClick={orderBy(item.title)}>
                                                {item.title}
                                                {order.label == item.title ? (
                                                    (order.type == "ASC" ?
                                                        <i className='material-icons'>arrow_downward</i>
                                                        :
                                                        <i className='material-icons'>arrow_upward</i>)

                                                ) : (
                                                        <i className='material-icons datatable-default-order-icon'>arrow_upward</i>
                                                    )
                                                }
                                            </span>
                                        </th>
                                    ))}
                                </thead>
                                <tbody>
                                    {tableRows && tableRows.map((row, index) => (
                                        <tr key={index}>
                                            <td> <Link to={`/admin/product_detail/${row.productId}`}>+ info</Link></td>
                                            <td>{index + 1}</td>
                                            <td>{row.name}</td>
                                            <td>{row.price}</td>
                                            <td>{row.alt_price}</td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Table;