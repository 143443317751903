import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import UIkit from 'uikit';
import MDUIkit from 'md-uikit';
import MaterialTable from 'material-table';

import MaterialDataTable from '../../../components/datatable_vouchers.component';


import UserActions from '../../../providers/user/user.actions';
import { config, settings } from '../../../settings.js';

const Products = () => {
    const [newVoucherInfo, setnewVoucherInfo] = useState([]);
    const [billingArray, setBillingArray] = useState({
        columns: [],
        data: [],
    });

    const user = UserActions();
    const language_settings = settings.language.data.admin.pages["billing"];

    async function fetchData() {
        console.log("fetchData");
        try {
            const req = {
                "jwt": user.currentUser.token
            };
            console.log(req);
            const response = await fetch(`${config.connectionString}/api/order_read.php`, {
                method: 'POST',
                body: JSON.stringify(req)
            });
            if (response.status >= 400 && response.status < 500) {
                UIkit.notification('No hay data para mostrar.');
                throw "Error" + response.status;
            }
            if (response.status >= 500 && response.status < 600) {
                UIkit.notification('Error. Please try later.');
            }
            const res = await response.json();

            setBillingArray({
                columns: [
                    { title: "Id", field: 'orderId' },
                    { title: language_settings.table.fields.productName[user.currentUser.language], field: 'productName' },
                    { title: language_settings.table.fields.name[user.currentUser.language], field: 'name' },
                    { title: language_settings.table.fields.email[user.currentUser.language], field: 'email' },
                    { title: language_settings.table.fields.voucherCode[user.currentUser.language], field: 'voucherCode' },
                    { title: language_settings.table.fields.subtotal[user.currentUser.language], field: 'subtotal' },
                    { title: language_settings.table.fields.total[user.currentUser.language], field: 'total' },
                    { title: language_settings.table.fields.created_date[user.currentUser.language], field: 'created_date' },
                    
                    /* { title: 'Birth Year', field: 'birthYear', type: 'numeric' },
                    {
                        title: 'Birth Place',
                        field: 'birthCity',
                        lookup: { 34: 'İstanbul', 63: 'Şanlıurfa' },
                    } */
                ], data: res
            });
            /* modal.hide(); */
            console.log(res);
        } catch (error) {
            /*  modal.hide(); */
            console.log(error);
        }
    }

    useEffect(() => {
        MDUIkit.components();
        fetchData();
        console.log(user.currentUser);
    }, []);

    return (
        <>
            <div data-uk-grid>
                <div className="uk-width-1-1@m" >
                    {billingArray.data && billingArray.data.length > 0 ? (
                        <MaterialTable
                            title=""
                            options={{
                                sorting: true
                            }}
                            columns={billingArray.columns}
                            data={billingArray.data}
                            localization={{
                                pagination: {
                                    labelDisplayedRows: '{from}-{to} de {count}',
                                    labelRowsSelect: 'registros'
                                },
                                toolbar: {
                                    searchTooltip: 'Buscar',
                                    searchPlaceholder: 'Buscar'
                                },
                                body: {
                                    emptyDataSourceMessage: 'No hay registros para mostrar',
                                    filterRow: {
                                        filterTooltip: 'Filter'
                                    }
                                }
                            }}
                        />
                    ) : (
                            <p>No hay data.</p>
                        )}
                </div>
            </div>
            
        </>
    );
}
export default Products;