import React from 'react';
import { NavLink } from 'react-router-dom'

import BGLogin from '../../assets/img/testimonials_bg.jpg'
import Footer from './footer';
import Header from './header';
const Terms = () => {
    return (
        <div>
            <Header />
            <div className="uk-width-1-1">
                <div class="uk-background-cover uk-panel uk-flex uk-flex-center uk-flex-middle" style={{ backgroundImage: `url(${BGLogin})` }}>
                    <div className="uk-padding">
                        <h2 className="md-color-white">Terminos y Condiciones</h2>

                    </div>
                </div>
            </div>
            <div className="uk-section">
                <div className="uk-container">
                    <h5>INFORMACIÓN RELEVANTE</h5>
                    <p align="justify">Es requisito necesario para la adquisición de los productos que se ofrecen en este sitio, que lea y acepte los siguientes Términos y Condiciones que a continuación se redactan. El uso de nuestros servicios, así como la compra de nuestros productos implica que usted ha leído y aceptado los Términos y Condiciones de Uso en el presente documento.  Todos los planes que son ofrecidos por nuestro sitio Web no pueden ser recreados, cobrados, enviados o presentados por una Página Web tercera. En algunos casos, para adquirir un producto, será necesario el registro por parte del usuario, con ingreso de datos personales fidedignos y definición de una contraseña.</p>
                    <p align="justify">Todas las compras y transacciones que se lleven a cabo por medio de este sitio Web, están sujetas a un proceso de confirmación y verificación, el cual podría incluir la verificación del stock y disponibilidad del servicio y/o producto, validación de la forma de pago y el cumplimiento de las condiciones requeridas por el medio de pago seleccionado. En algunos casos puede que se requiera una verificación por medio de correo electrónico.</p>
                    <p align="justify">Los precios de los productos ofrecidos en esta Tienda Online son válidos solamente en las compras realizadas en este sitio Web.</p>

                    <h5>LICENCIA</h5>
                    <p align="justify">MAT TRAINING a través de su sitio Web concede una licencia para que los usuarios utilicen los productos que son vendidos en este sitio Web de acuerdo a los Términos y Condiciones que se describen en este documento.</p>

                    <h5>USO NO AUTORIZADO</h5>
                    <p align="justify">En caso de que aplique (para venta de software, templetes, u otro producto de diseño y programación) usted no puede colocar uno de nuestros productos, modificado o sin modificar, en un CD, sitio web o ningún otro medio y ofrecerlos para la redistribución o la reventa de ningún tipo.</p>

                    <h5>PROPIEDAD</h5>
                    <p align="justify">Usted no puede declarar propiedad intelectual o exclusiva a ninguno de nuestros productos, modificado o sin modificar. Todos los productos son propiedad de los proveedores del contenido. En caso de que no se especifique lo contrario, nuestros productos se proporcionan sin ningún tipo de garantía, expresa o implícita. En ningún caso esta compañía será responsables de ningún daño incluyendo, pero no limitado a, daños directos, indirectos, especiales, fortuitos o consecuentes u otras pérdidas resultantes del uso o de la imposibilidad de utilizar nuestros productos y/o servicios.</p>

                    <h5>POLÍTICA DE REEMBOLSO Y GARANTÍA</h5>
                    <p align="justify">Para los planes o membresías pagadas no hay devolución de ningún tipo, ni extensión de la membresía, ya sea por; enfermedades, lesiones, viajes o por cualquier razón externa. La única excepción será por un error humano o de sistema que haya generado un doble pago. Le pedimos que lea cuidadosamente antes de realizar cualquier compra en el site. Sobre productos físicos, hacemos solamente excepciones con esta regla cuando la descripción no se ajusta al producto. Hay algunos productos que pudieran tener garantía y posibilidad de reembolso, pero este será especificado al comprar el producto. En tales casos la garantía sólo cubrirá fallas de fábrica y sólo se hará efectiva cuando el producto se haya usado correctamente. La garantía no cubre averías o daños ocasionados por uso indebido. Los términos de la garantía están asociados a fallas de fabricación y funcionamiento en condiciones normales de los productos y sólo se harán efectivos estos términos si el equipo ha sido usado correctamente.</p>
                    <p>Esto incluye:</p>
                    <ul>
                        <li>De acuerdo a las especificaciones técnicas indicadas para cada producto.</li>
                        <li>En condiciones ambientales acorde con las especificaciones indicadas por el fabricante.</li>
                        <li>En uso específico para la función con que fue diseñado de fábrica.</li>
                        <li>En condiciones de operación eléctricas acorde con las especificaciones y tolerancias indicadas.</li>
                    </ul>
                    <h5>PLANES O MEMBRESÍAS PAGADAS</h5>
                    
                    <h6>Plan MAT Avanzado</h6>
                    <p align="justify">Da acceso a las clases de   entrenamiento personalizado por horarios con cupos limitados y nutrición personalizada, además a las materclasses y webinars especiales.</p>
                    <h6>Plan MAT Élite</h6>
                    <p align="justify">Da acceso a una asesoría mensual con MICHAEL COACH  1 a 1 que incluye a un plan de entrenamiento y nutrición personalizada con monitoreo vía zoom, además a las materclasses y webinars especiales.
Este plan se rige a acceso limitado ya que primero deberá coordinar disponibilidad de horario vía whatsapp o correo.

</p>
                    <h6>Plan MAT Kids</h6>
                    <p align="justify">Clases en vivo para los niños. Este servicio debe ser contratado, debidamente autorizado y supervisado por los padres o representantes del menor de edad.</p>
                    <p align="justify">
                    Los horarios para las clases en vivo estarán en el inicio de la web, deberá suscribirse a un solo horario al momento de adquirir el programa.
                </p>
                    <p align="justify">COMPROBACIÓN ANTIFRAUDE a compra del cliente puede ser aplazada para la comprobación antifraude. También puede ser suspendida por más tiempo para una investigación más rigurosa, para evitar transacciones fraudulentas.</p>

                    <h5>OBLIGACIONES DE USUARIO</h5>
                    <p align="justify">El usuario se responsabiliza por los daños que pudiera tener a una mala ejecución todos los planes como consecuencia de prácticas negligentes o usos incorrectos de los equipos sin la supervisión de un entrenador, debidamente comprobados.</p>
                    <p align="justify">Es requisito indispensable del usuario si se sufriese de alguna dolencia o problema físico hacerlo llegar previo inicio del plan mat avanzado y plan mat elite para regirnos a ello previa estructuración de programa para que sea eficiente sin mermar la salud que es nuestra prioridad.
                    En caso de plan mat básico inicio de cada sesión en vivo indicarlo para evitar o dar alguna variante de ejercicio.
</p>
                    <p align="justify">NOTA: Todo plan que incluya nutrición o entrenamiento personalizada se enviara al correo registrado previa datos necesarios para la estructuración de dicho programa. El tiempo de programa será no máximo de 48 hr, apenas sea enviado recién comenzará el programa obtenido por 1 mes.</p>

                    <h5>PRIVACIDAD</h5>
                    <p align="justify">Este sitio Web  garantiza que la información personal que usted envía, cuenta con la seguridad necesaria. Los datos ingresados por usuario o en el caso de requerir una validación de los pedidos no serán entregados a terceros, salvo que deba ser revelada en cumplimiento a una orden judicial o requerimientos legales.</p>
                    <p align="justify">Al momento de la de suscripción aceptaría boletines de correos electrónicos publicitarios.</p>
                    <p align="justify">MICHAEL COACH reserva los derechos de cambiar o de modificar estos términos sin previo aviso.</p>

                </div>
            </div>
            <Footer />
        </div >
    );
}

export default Terms;
