import React, { useState, useEffect } from 'react';

import UIkit from 'uikit';
import axios from 'axios';
import MDUIkit from 'md-uikit';
import Slim from '../../assets/slim/slim.react';
import { useParams, useHistory } from "react-router-dom";


import UserActions from '../../providers/user/user.actions';
import { config, settings } from '../../settings.js';

const Products = () => {
    const [voucherInfo, setVoucherInfo] = useState([]);

    const user = UserActions();
    let history = useHistory();
    let { id } = useParams();
    const language_settings = settings.language.data.admin.pages["vouchers"];

    const handleChange = event => {
        event.preventDefault();
        const { value, name } = event.target;
        console.log(name);
        console.log(name + " " + value);
        setVoucherInfo({ ...voucherInfo, [name]: value });
    }

    async function fetchVoucherInfo() {

        try {
            const req = {
                "voucherId": id,
                "jwt": user.currentUser.token
            };
            const response = await fetch(`${config.connectionString}/api/voucher_read_by_id.php`, {
                method: 'POST',
                body: JSON.stringify(req)
            });
            if (response.status >= 400 && response.status < 500) {
                UIkit.notification('Information error.');
                throw "Error" + response.status;
            }
            if (response.status >= 500 && response.status < 600) {
                UIkit.notification('Error. Please try later.');
            }
            const res = await response.json();

            setVoucherInfo(res);
            /* modal.hide(); */
            console.log(res);
        } catch (error) {
            /*  modal.hide(); */
            console.log(error);
        }
    }
    useEffect(() => {
        MDUIkit.components();
        console.log("id : " + id);
        fetchVoucherInfo();
    }, []);

    async function sendInfo(newObj) {
        try {
            const req = {
                "voucherId": voucherInfo.voucherId,
                "code": voucherInfo.code,
                "amount": voucherInfo.amount,
                "type_discount": voucherInfo.type_discount,
                "valid_date": voucherInfo.valid_date,
                "type_duration": voucherInfo.type_duration,
                "active": voucherInfo.active,
                "jwt": user.currentUser.token
            };
            console.log("sendInfo");
            console.log(JSON.stringify(req));
            var modal = UIkit.modal.dialog('<div className=\'uk-modal-body\'><p>Cargando..</p></div>', { 'bgClose': false, 'escClose': false });

            const response = await fetch(`${config.connectionString}/api/voucher_update.php`, {
                method: 'POST',
                body: JSON.stringify(req)
            });
            if (response.status >= 400 && response.status < 500) {
                UIkit.notification('Information error.');
                modal.hide();
                throw "Error" + response.status;
            }
            if (response.status >= 500 && response.status < 600) {
                modal.hide();
                UIkit.notification('Error. Please try later.');
            }
            const res = await response.json();
            modal.hide();
            console.log(res);
            UIkit.notification("Información actualizada!", "success");
            history.goBack();
        } catch (error) {
            modal.hide();
            console.log(error);
        }
    }
    const onClickUpdate = () => {
        let flagWhites = true;
        let flagGeneral = true;
        if (voucherInfo.code === "" || voucherInfo.amount === "") {
            flagWhites = false;
        }
        if (!flagWhites) {
            flagGeneral = false;
            UIkit.notification('Complete los espacios en blanco.', 'danger');
        }
        if (flagGeneral) {
            sendInfo();
        }
    }
    return (
        <>
            <div className="content-before"></div>
            <span>
                <h4 className="md-color-white">
                    <a onClick={() => history.goBack()} >
                        <i className="material-icons md-color-white md-icon">keyboard_arrow_left</i></a>
                Editar Voucher</h4>
            </span>

            <p className={`${settings.colors.primary_text}`}>{language_settings.edit_text[user.currentUser.language]}</p>
            {voucherInfo.code ?
                <div className="uk-card uk-card-body">
                    <div className=" uk-grid-small" data-uk-grid>
                        <div class="uk-width-1-3@m">
                            {voucherInfo.profileImg &&
                                <img src={`${config.connectionString}${voucherInfo.profileImg}`} />
                            }
                        </div>
                        <div class="uk-width-2-3@m">
                            <div className=" uk-grid-small" data-uk-grid>
                                <div class="uk-width-1-1">
                                    <div class="md-input-wrapper md-input-filled ">
                                        <label>{language_settings.new_modal.fields.code[user.currentUser.language]}</label>
                                        <input type="text" name='code' class="md-input" onChange={handleChange} required value={voucherInfo.code} />
                                        <span class="md-input-bar "></span>
                                    </div>
                                </div>
                                <div class="uk-width-1-1">
                                    <div class="md-input-wrapper md-input-filled md-select">
                                        <label>{language_settings.new_modal.fields.type_discount[user.currentUser.language]}</label>
                                        <select class="md-input " name="type_discount" onChange={handleChange} required value={voucherInfo.type_discount} >
                                            <option disabled selected hidden></option>
                                            <option value="Porcentaje">Porcentaje</option>
                                            <option value="Monto de dinero">Monto de dinero</option>
                                        </select>
                                        <span class="md-input-bar "></span>
                                    </div>
                                </div>
                                <div class="uk-width-1-1">
                                    <div class="md-input-wrapper md-input-filled md-input-wrapper-helper-text ">
                                        <label>{language_settings.new_modal.fields.amount[user.currentUser.language]}</label>
                                        <input type="number" name='amount' class="md-input" onChange={handleChange} required value={voucherInfo.amount} />
                                        <span class="md-input-bar "></span>
                                        <p>Ingresar un número ya sea para porcentaje o monto de dinero</p>

                                    </div>
                                </div>

                                <div class="uk-width-1-1">
                                    <div class="md-input-wrapper md-input-filled md-select">
                                        <label>{language_settings.new_modal.fields.type_duration[user.currentUser.language]}</label>
                                        <select class="md-input" name="type_duration" onChange={handleChange} required value={voucherInfo.type_duration} >
                                            <option disabled selected hidden></option>
                                            <option value="Hasta una fecha">Hasta una fecha</option>
                                            <option value="Indefinido">Indefinido</option>
                                        </select>
                                        <span class="md-input-bar "></span>
                                    </div>
                                </div>
                                <div class="uk-width-1-1">
                                    <div class="md-input-wrapper md-input-filled ">
                                        <label>{language_settings.new_modal.fields.valid_date[user.currentUser.language]}</label>
                                        <input type="date" name='valid_date' class="md-input" onChange={handleChange} required value={voucherInfo.valid_date} />
                                        <span class="md-input-bar "></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : <p>Cargando...</p>
            }

            <div class="md-fab-wrapper">
                <a onClick={onClickUpdate} class="md-fab md-fab-primary md-fab-wave-light" ><i class="material-icons">save</i></a>
            </div>
        </>
    );
}
export default Products;