import React, { useState, useEffect } from 'react';

import { Route } from "react-router-dom";

import UIkit from 'uikit';
import MDUIkit from 'md-uikit';
import { Link, useLocation } from 'react-router-dom';

import Packages from './components/packages_packages';
import Vouchers from './components/packages_vouchers';


import UserActions from '../../providers/user/user.actions';
import { config, settings } from '../../settings.js';

const Products = () => {
    let location = useLocation();
    const language_settings = settings.language.data.admin;
    const user = UserActions();

    const [tabsNames, setTabsNames] = useState([{
        title: language_settings.pages["packages"].page_name[user.currentUser.language],
        url: "/admin/packages/packages"
    }, {
        title: language_settings.pages["vouchers"].page_name[user.currentUser.language],
        url: "/admin/packages/vouchers"
    }]);

    useEffect(() => {
        console.log(location);
        MDUIkit.components();
    }, []);
    return (
        <>
            <div className={`content-before`}></div>
            <div data-uk-grid>
                <div className="uk-width-1-1@m" >
                    <ul className="uk-navbar-nav">
                        {tabsNames && tabsNames.map(item => (
                            <li>
                                <Link to={item.url}>
                                    {location.pathname == item.url ?
                                        <h4 className={`${settings.colors.primary_text}`} >{item.title}</h4>
                                        :
                                        <h4 className={`${settings.colors.primary_text}`} style={{opacity: "0.5"}} >{item.title}</h4>
                                    }
                                </Link>
                            </li>
                        ))}
                    </ul>

                    <Route exact path='/admin/packages/packages' component={Packages} />
                    <Route exact path='/admin/packages/vouchers' component={Vouchers} />
                </div>
            </div>
        </>
    );
}
export default Products;