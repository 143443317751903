import React, { useContext, useEffect } from 'react';
/* import UIkit from 'uikit'; */
import { HashRouter, NavLink, Route } from "react-router-dom";
import MDUIkit from 'md-uikit';
import Logo from "../../assets/img/main_logo.png";

import 'md-uikit/dist/css/md-uikit.css';
import Routes from './routes.js';

import UserActions from '../../providers/user/user.actions';
import bgImg from '../../assets/img/sidebar_background.jpg';

const SideBar = () => {
  const user = UserActions();
  useEffect(() => {
    MDUIkit.drawer();
  })
  return (
    <HashRouter>
      <aside id="md-drawer" uk-offcanvas="overlay: true">
        <div className="uk-offcanvas-bar" >
          {/* <div className="uk-overlay-default uk-position-cover rgba-black-strong"></div>
          <div className="md-drawer_content" style={{ backgroundImage: `url(${bgImg})`, backgroundSize: "cover" }}></div>
           */}<div className="md-drawer_content">
            <div className="drawer_header">
              <div className="uk-padding-small">
                <img src={Logo} />

              </div></div>
            <br />
            <ul uk-nav="multiple: true">
              {Routes.map((route, key) => (
                route.rol == user.currentUser.rol && (
                  <Route
                    key={key}
                    path={route.path}
                    children={({ match }) => (
                      <li className={route.path ? (match && "uk-active") : (route.submenu && 'uk-parent')}>
                        <NavLink to={route.url}>
                          <div class="ripple-surface">
                            <span className="menu_icon"><i className="material-icons">{route.icon}</i></span>
                            <span className="menu_title">{route.name}</span>
                          </div>
                        </NavLink>

                        {route.submenu &&
                          <ul className="uk-nav-sub">
                            {route.submenu.map((inner_route, key) =>
                              <Route
                                path={inner_route.url}
                                children={({ match }) => (
                                  match ? (
                                    <li className="uk-active">
                                      <NavLink to={inner_route.path}>{inner_route.name}</NavLink>
                                    </li>
                                  ) : (
                                      <li className="ripple-surface">
                                        <NavLink to={inner_route.path}>{inner_route.name}</NavLink>
                                      </li>
                                    )
                                )}
                              />
                            )}
                          </ul>
                        }
                      </li>
                    )}
                  />)
              )
              )}
            </ul>
          </div>
        </div>
      </aside>
    </HashRouter>
  )
}

export default SideBar;