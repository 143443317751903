import React, { useState, useEffect } from 'react';

import UIkit from 'uikit';
import MDUIkit from 'md-uikit';

import MaterialDataTableClients from '../../components/datatable_clients.component';


import UserActions from '../../providers/user/user.actions';
import {config} from '../../settings.js';

const Products = () => {
    const [newPersonInfo, setNewPersonInfo] = useState({
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        city: "",
        address: "",
        dob: ""
    });
    const [productsArray, setProductsArray] = useState([]);

    const user = UserActions();
    const handleChange = event => {
        event.preventDefault();
        const { value, name } = event.target;
        setNewPersonInfo({ ...newPersonInfo, [name]: value });

    }
    async function fetchClients() {
        try { 
            const req = {
            "jwt": user.currentUser.token
        };
            const response = await fetch(`${config.connectionString}/api/person_read.php`, {
                method: 'POST',
                body: JSON.stringify(req)
            });
            if (response.status >= 400 && response.status < 500) {
                UIkit.notification('Information error.');
                throw "Error" + response.status;
            }
            if (response.status >= 500 && response.status < 600) {
                UIkit.notification('Error. Please try later.');
            }
            const res = await response.json();

            setProductsArray(res);
            /* modal.hide(); */
            console.log(res);
        } catch (error) {
           /*  modal.hide(); */
            console.log(error);
        }
    }
    useEffect(() => {
        MDUIkit.components();
        fetchClients();
    }, []);

    async function sendInfo() {
        try {
            const req = {
                "dni": newPersonInfo.dni,
                "firstname": newPersonInfo.firstname,
                "lastname": newPersonInfo.lastname,
                "email": newPersonInfo.email,
                "phone": newPersonInfo.phone,
                "dob": newPersonInfo.dob,
                "jwt": user.currentUser.token
            };
            console.log("sendInfo");
            console.log(JSON.stringify(req));
            var modal = UIkit.modal.dialog('<div class=\'uk-modal-body\'><p>Cargando..</p></div>', { 'bgClose': false, 'escClose': false });

            const response = await fetch(`${config.connectionString}/api/person_insert.php`, {
                method: 'POST',
                body: JSON.stringify(req)
            });
            if (response.status >= 400 && response.status < 500) {
                UIkit.notification('Information error.');
                modal.hide();
                throw "Error" + response.status;
            }
            if (response.status >= 500 && response.status < 600) {
                modal.hide();
                UIkit.notification('Error. Please try later.');
            }
            const res = await response.json();
            modal.hide();
            console.log(res);
            UIkit.modal("#modal-new").hide();
            window.location.reload();
            /* fetchClients(); */
        } catch (error) {
            modal.hide();
            alert(error);
        }
    }
    const onClickCreatePerson = () => {
        let flagWhites = true;
        let flagGeneral = true;
        if (newPersonInfo.dni === "") {
            flagWhites = false;
        }
        if (!flagWhites) {
            flagGeneral = false;
            UIkit.notification('Complete toda la información requerida.', 'danger');
        }
        if (flagGeneral) {
            sendInfo();
        }
    }

    return (
        <>
        <div className="content-before"></div>
            <div data-uk-grid>
                <div className="uk-width-1-1@m" >
                    <h4 className="md-color-white">Clientes</h4>
                        {productsArray.length > 0 ? (
                            < MaterialDataTableClients tableRowsArray={productsArray} />
                        ) : (
                                <p>No hay data.</p>
                            )}
                </div>
            </div>
            <div id="modal-new" data-uk-modal>
                <div className="uk-modal-dialog uk-margin-auto-vertical">
                    <div className="uk-modal-header">
                        <h2 className="uk-modal-title">Nuevo Persona</h2>
                    </div>
                    <div className="uk-modal-body uk-grid-small" data-uk-grid>
                        <div class="uk-width-1-1">
                            <div class="md-input-wrapper md-input-wrapper-outlined">
                                <label>DNI</label>
                                <input type="text" name='dni' class="md-input" onChange={handleChange} required value={newPersonInfo.dni} />
                            </div>
                        </div>
                        <div class="uk-width-1-1">
                            <div class="md-input-wrapper md-input-wrapper-outlined">
                                <label>Nombres</label>
                                <input type="text" name='firstname' class="md-input" onChange={handleChange} required value={newPersonInfo.firstname} />
                            </div>
                        </div>
                        <div class="uk-width-1-1">
                            <div class="md-input-wrapper md-input-wrapper-outlined ">
                                <label>Apellidos</label>
                                <input type="text" name='lastname' class="md-input" onChange={handleChange} required value={newPersonInfo.lastname} />
                            </div>
                        </div>
                        <div class="uk-width-1-1">
                            <div class="md-input-wrapper md-input-wrapper-outlined ">
                                <label>Email</label>
                                <input type="email" name='email' class="md-input" onChange={handleChange} required value={newPersonInfo.email} />
                            </div>
                        </div>
                        <div class="uk-width-1-1">
                            <div class="md-input-wrapper md-input-wrapper-outlined ">
                                <label>Telefono</label>
                                <input type="number" name='phone' class="md-input" onChange={handleChange} required value={newPersonInfo.phone} />
                            </div>
                        </div>
                        <div class="uk-width-1-1">
                            <div class="md-input-wrapper md-input-wrapper-outlined ">
                                <label>Fecha de Nacimiento</label>
                                <input type="date" name='dob' class="md-input" onChange={handleChange} required value={newPersonInfo.dob} />
                            </div>
                        </div>
                    </div>
                    <div class="uk-modal-footer uk-text-right">
                        <button class="md-btn md-btn-text md-btn-primar ripple-surface uk-modal-close" type="button">Cancelar</button>
                        <button onClick={onClickCreatePerson} className="md-btn md-color-white md-btn-primary ripple-surface">Crear Persona</button>
                    </div>
                </div>
            </div>
            <div class="md-fab-wrapper">
                <a data-uk-toggle="target: #modal-new" class="md-fab md-fab-primary ripple-surface" href="javascript:void(0)"><i class="material-icons">add</i></a>
            </div>
        </>
    );
}
export default Products;