import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import UIkit from 'uikit';
import MDUIkit from 'md-uikit';
import MaterialTable from 'material-table';

import MaterialDataTable from '../../../components/datatable_vouchers.component';


import UserActions from '../../../providers/user/user.actions';
import { config, settings } from '../../../settings.js';

const Products = () => {
    const [newVoucherInfo, setnewVoucherInfo] = useState([]);
    const [voucherArray, setVoucherArray] = useState({
        columns: [],
        data: [],
    });

    const user = UserActions();
    const language_settings = settings.language.data.admin.pages["vouchers"];

    const handleChange = event => {
        event.preventDefault();
        const { value, name } = event.target;
        setnewVoucherInfo({ ...newVoucherInfo, [name]: value });

    }
    const onDeleteClick = (voucherId) => (e) => {
        e.preventDefault();

        if (window.confirm('Estas Seguro?')) {
            sendDelete(voucherId);
        }
    }
    async function fetchData() {

        try {
            const req = {
                "jwt": user.currentUser.token
            };
            const response = await fetch(`${config.connectionString}/api/voucher_read.php`, {
                method: 'POST',
                body: JSON.stringify(req)
            });
            if (response.status >= 400 && response.status < 500) {
                UIkit.notification('Information error.');
                throw "Error" + response.status;
            }
            if (response.status >= 500 && response.status < 600) {
                UIkit.notification('Error. Please try later.');
            }
            const res = await response.json();

            setVoucherArray({
                columns: [
                    {
                        title: 'Acciones',
                        field: 'url',
                        render: rowData => (
                            <>
                                {/* <Link to={`/admin/client_detail/${rowData.id}`}>+ info</Link> */}
                                <Link to={`../vouchers_detail/${rowData.voucherId}`}><i className="material-icons md-icon">edit</i> </Link>
                                <a onClick={onDeleteClick(rowData.voucherId)}><i className="material-icons md-icon">delete</i> </a>
                            </>
                        )
                    },
                    { title: language_settings.new_modal.fields.code[user.currentUser.language], field: 'code' },
                    {
                        title: 'Descuento',
                        render: rowData => (
                            <>
                                {rowData.type_discount == "Porcentaje" ?
                                    rowData.amount + "%" : rowData.amount + "$"
                                }
                            </>
                        )
                    },
                    {
                        title: 'Expira',
                        render: rowData => (
                            <>
                                {rowData.type_duration == "Indefinido" ?
                                    "No expira" : rowData.valid_date
                                }
                            </>
                        )
                    },
                    /* { title: 'Birth Year', field: 'birthYear', type: 'numeric' },
                    {
                        title: 'Birth Place',
                        field: 'birthCity',
                        lookup: { 34: 'İstanbul', 63: 'Şanlıurfa' },
                    } */
                ], data: res
            });
            /* modal.hide(); */
            console.log(res);
        } catch (error) {
            /*  modal.hide(); */
            console.log(error);
        }
    }

    async function sendDelete(voucherId) {
        try {
            const req = {
                "voucherId": voucherId,
                "jwt": user.currentUser.token
            };
            console.log("sendInfo");
            console.log(JSON.stringify(req));
            var modal = UIkit.modal.dialog('<div className=\'uk-modal-body\'><p>Cargando..</p></div>', { 'bgClose': false, 'escClose': false });

            const response = await fetch(`${config.connectionString}/api/voucher_delete.php`, {
                method: 'POST',
                body: JSON.stringify(req)
            });
            if (response.status >= 400 && response.status < 500) {
                UIkit.notification('Information error.');
                //modal.hide();
                throw "Error" + response.status;
            }
            if (response.status >= 500 && response.status < 600) {
                //modal.hide();
                UIkit.notification('Error. Please try later.');
            }
            const res = await response.json();
            modal.hide();
            console.log(res);
            UIkit.notification("Información actualizada!", "success");
            fetchData();
        } catch (error) {
            modal.hide();
            console.log(error);
        }
    }
    useEffect(() => {
        MDUIkit.components();
        fetchData();
        console.log(user.currentUser);
    }, []);

    async function sendInfo() {
        try {
            const req = {
                "code": newVoucherInfo.code,
                "amount": newVoucherInfo.amount,
                "type_discount": newVoucherInfo.type_discount,
                "valid_date": newVoucherInfo.valid_date,
                "type_duration": newVoucherInfo.type_duration,
                "active": true,
                "jwt": user.currentUser.token
            };
            console.log("sendInfo");
            console.log(JSON.stringify(req));
            var modal = UIkit.modal.dialog('<div class=\'uk-modal-body\'><p>Cargando..</p></div>', { 'bgClose': false, 'escClose': false });

            const response = await fetch(`${config.connectionString}/api/voucher_insert.php`, {
                method: 'POST',
                body: JSON.stringify(req)
            });
            if (response.status >= 400 && response.status < 500) {
                UIkit.notification('Information error.');
                //modal.hide();
                throw "Error" + response.status;
            }
            if (response.status >= 500 && response.status < 600) {
                //modal.hide();
                UIkit.notification('Error. Please try later.');
            }
            const res = await response.json();
            modal.hide();
            console.log(res);
            UIkit.modal("#modal-new").hide();
            window.location.reload();
            /* fetchData(); */
        } catch (error) {
            //modal.hide();
            UIkit.modal("#modal-new").show();
            console.log(error);
        }
    }
    const onClickCreate = () => {
        sendInfo();
    }
    console.log(newVoucherInfo);
    return (
        <>
            <div data-uk-grid>
                <div className="uk-width-1-1@m" >
                    <p className={`${settings.colors.primary_text}`}>{language_settings.add_new_text[user.currentUser.language]}</p>
                    {voucherArray.data && voucherArray.data.length > 0 ? (
                        <MaterialTable
                            title=""
                            options={{
                                sorting: true
                            }}
                            columns={voucherArray.columns}
                            data={voucherArray.data}
                            localization={{
                                pagination: {
                                    labelDisplayedRows: '{from}-{to} de {count}',
                                    labelRowsSelect: 'registros'
                                },
                                toolbar: {
                                    searchTooltip: 'Buscar',
                                    searchPlaceholder: 'Buscar'
                                },
                                body: {
                                    emptyDataSourceMessage: 'No hay registros para mostrar',
                                    filterRow: {
                                        filterTooltip: 'Filter'
                                    }
                                }
                            }}
                        />
                    ) : (
                            <p>No hay data.</p>
                        )}
                </div>
            </div>
            <div id="modal-new" data-uk-modal>
                <div className="uk-modal-dialog uk-margin-auto-vertical">
                    <div className="uk-modal-header">
                        <h2 className="uk-modal-title">{language_settings.new_modal.title[user.currentUser.language]}</h2>
                    </div>
                    <div className="uk-modal-body uk-grid-small" data-uk-grid>
                        <div class="uk-width-1-1">
                            <div class="md-input-wrapper ">
                                <label>{language_settings.new_modal.fields.code[user.currentUser.language]}</label>
                                <input type="text" name='code' class="md-input" onChange={handleChange} required value={newVoucherInfo.code} />
                                <span class="md-input-bar "></span>
                            </div>
                        </div>
                        <div class="uk-width-1-1">
                            <div class="md-input-wrapper md-select">
                                <label>{language_settings.new_modal.fields.type_discount[user.currentUser.language]}</label>
                                <select class="md-input " name="type_discount" onChange={handleChange} required value={newVoucherInfo.type_discount} >
                                    <option disabled selected hidden></option>
                                    <option value="Porcentaje">Porcentaje</option>
                                    <option value="Monto de dinero">Monto de dinero</option>
                                </select>
                                <span class="md-input-bar "></span>
                            </div>
                        </div>
                        <div class="uk-width-1-1">
                            <div class="md-input-wrapper md-input-wrapper-helper-text ">
                                <label>{language_settings.new_modal.fields.amount[user.currentUser.language]}</label>
                                <input type="number" name='amount' class="md-input" onChange={handleChange} required value={newVoucherInfo.amount} />
                                <span class="md-input-bar "></span>
                                <p>Ingresar un número ya sea para porcentaje o monto de dinero</p>
                            </div>
                        </div>

                        <div class="uk-width-1-1">
                            <div class="md-input-wrapper md-select">
                                <label>{language_settings.new_modal.fields.type_duration[user.currentUser.language]}</label>
                                <select class="md-input" name="type_duration" onChange={handleChange} required value={newVoucherInfo.type_duration} >
                                    <option disabled selected hidden></option>
                                    <option value="Hasta una fecha">Hasta una fecha</option>
                                    <option value="Indefinido">Indefinido</option>
                                </select>
                                <span class="md-input-bar "></span>
                            </div>
                        </div>
                        <div class="uk-width-1-1">
                            <div class="md-input-wrapper md-input-filled ">
                                <label>{language_settings.new_modal.fields.valid_date[user.currentUser.language]}</label>
                                <input type="date" name='valid_date' class="md-input" onChange={handleChange} required value={newVoucherInfo.valid_date} />
                                <span class="md-input-bar "></span>
                            </div>
                        </div>
                    </div>
                    <div class="uk-modal-footer uk-text-right">
                        <button class="md-btn md-btn-text uk-modal-close" type="button">Cancelar</button>
                        <button onClick={onClickCreate} className="md-btn md-color-white md-btn-primary">Crear Voucher</button>
                    </div>
                </div>
            </div>
            <div class="md-fab-wrapper">
                <a data-uk-toggle="target: #modal-new" class="md-fab md-fab-primary md-fab-wave-light" href="javascript:void(0)"><i class="material-icons">add</i></a>
            </div>
        </>
    );
}
export default Products;