
export const INITIAL_STATE = {
    currentUser: null
}
const userReducer = (state, action) => {
    switch (action.type) {
        case "SET_CURRENT_USER":
            return {
                ...state,
                currentUser: action.payload
            };
        case "RESET_USER":
            return {
                state: INITIAL_STATE
            };
        default:
            return state;
    }
}
export default userReducer;