import Orders from "./Clients.js";
import Products from "./ProductsScreen.js";
import Banners from "./Banners.js";
import FinancialScreen from "./FinancialScreen.js";

const Routes = [
  {
    name: "Clientes",
    path: "/admin/clients",
    url: "/admin/clients",
    icon: 'person',
    component: Orders,
    rol: '2'
  },
  {
    name: "Productos",
    path: "/admin/packages/",
    url: "/admin/packages/packages",
    icon: 'category',
    component: Products,
    rol: '2'
  },
  
  {
    name: "Finanzas",
    path: "/admin/financials/",
    url: "/admin/financials/billing",
    icon: 'payment',
    component: FinancialScreen,
    rol: '2'
  },
  {
    name: "Banners",
    path: "/admin/banners",
    url: "/admin/banners",
    icon: 'settings',
    component: Banners,
    rol: '2'
  }
];

export default Routes;
