import React, { useState, useEffect } from 'react';

import UIkit from 'uikit';
import axios from 'axios';
import MDUIkit from 'md-uikit';
import Slim from '../../assets/slim/slim.react';
import { Link } from "react-router-dom";

import MaterialDataTableClients from '../../components/datatable_clients.component';


import UserActions from '../../providers/user/user.actions';
import {config} from '../../settings.js';

const Products = () => {
    const [newBannerInfo, setNewBannerInfo] = useState({
        title: "",
        dateFrom: "2020-01-01",
        dateTo: "2020-01-01",
        visible: "1",
        img: ""
    });
    const [bannersArray, setBannersArray] = useState([]);
    const [sizeExceeded, setSizeExceeded] = useState(false);
    const [selectedFile, setSelectedFile] = useState([]);

    const user = UserActions();
    const handleChange = event => {
        event.preventDefault();
        const { value, name } = event.target;
        console.log(name);
        console.log(name + " " + value);
        setNewBannerInfo({ ...newBannerInfo, [name]: value });
    }

    const handleChangeRadio = event => {
        const { value } = event.target;
        console.log(value);
        setNewBannerInfo({ ...newBannerInfo, visible: value });

    }
    async function fetchBanners() {

        try {
            const req = {
                "jwt": user.currentUser.token
            };
            const response = await fetch(`${config.connectionString}/api/banner_read.php`, {
                method: 'POST',
                body: JSON.stringify(req)
            });
            if (response.status >= 400 && response.status < 500) {
                UIkit.notification('Information error.');
                throw "Error" + response.status;
            }
            if (response.status >= 500 && response.status < 600) {
                UIkit.notification('Error. Please try later.');
            }
            const res = await response.json();

            setBannersArray(res);
            /* modal.hide(); */
            console.log(res);
        } catch (error) {
            /*  modal.hide(); */
            console.log(error);
        }
    }
    useEffect(() => {
        MDUIkit.components();
        fetchBanners();
    }, []);

    async function sendInfo(newObj) {
        try {
            const req = {
                "title": newObj.title,
                "img": newObj.img,
                "visible": newObj.visible,
                "dateFrom": newObj.dateFrom,
                "dateTo": newObj.dateTo,
                "jwt": user.currentUser.token
            };
            console.log("sendInfo");
            console.log(JSON.stringify(req));
            var modal = UIkit.modal.dialog('<div className=\'uk-modal-body\'><p>Cargando..</p></div>', { 'bgClose': false, 'escClose': false });

            const response = await fetch(`${config.connectionString}/api/banner_insert.php`, {
                method: 'POST',
                body: JSON.stringify(req)
            });
            if (response.status >= 400 && response.status < 500) {
                UIkit.notification('Information error.');
                //modal.hide();
                throw "Error" + response.status;
            }
            if (response.status >= 500 && response.status < 600) {
                //modal.hide();
                UIkit.notification('Error. Please try later.');
            }
            const res = await response.json();
            modal.hide();
            console.log(res);
            UIkit.modal("#modal-new").hide();
            window.location.reload();
            /* fetchBanners(); */
        } catch (error) {
            //modal.hide();
            console.log(error);
        }
    }
    const UploadDocument = event => {
        event.preventDefault();
        let flagWhites = true;
        let flagGeneral = true;
        if (newBannerInfo.title === "") {
            flagWhites = false;
        }
        if (!flagWhites) {
            flagGeneral = false;
            UIkit.notification('Complete toda la información requerida.', 'danger');
        }
        if (selectedFile.file == undefined) {
            flagGeneral = false;
            UIkit.notification("Please select a file", "danger");
        }
        console.log(flagGeneral);
        if (flagGeneral) {
            uploadHandler();
        }
    }
    const uploadHandler = () => {
        const formData = new FormData();

        console.log("Uploading file...");
        let fileName = selectedFile.name;
        let fileContent = selectedFile.file;
        formData.append('code', "123");
        formData.append(
            'myFile_0',
            fileContent,
            fileName
        );
        axios.post(`${config.connectionString}/api/script_insertImage.php`, formData, {
            onUploadProgress: progressEvent => {
                console.log(progressEvent.loaded / progressEvent.total)
            }
        })
            .then(response => {
                console.log(response);
                const newObj = { ...newBannerInfo };
                newObj.img = response.data.url;
                //setNewBannerInfo({ ...newBannerInfo, img: response.data.url });

                sendInfo(newObj);

            }).catch(function (error) {
                console.log(error);
            });
    }
    const didConfirmSlim = (data) => {
        // slim instance reference
        console.log(data);
        console.log(data.output.image);
        var canvas = data.output.image;
        var fileName = data.input.name;
        canvas.toBlob(function (blob) {
            setSelectedFile({ file: blob, name: fileName });
        });
    }
    return (
        <>

            <div className="content-before"></div>
            <div data-uk-grid>
                <div className="uk-width-1-1@m" >
                    <h4 className="text-on-app-bar">Banners</h4>
                    <div className="uk-card uk-card-body">
                        <div data-uk-grid>
                            {bannersArray.length > 0 ? (
                                bannersArray.map(item => (
                                    <div className="uk-width-1-3@m uk-width-1-2@s" >
                                        <div className="uk-card uk-card-body outlined uk-text-center" >
                                            <h5>{item.title}</h5>
                                            <p>
                                                {(() => {
                                                    switch (item.visible) {
                                                        case '1':
                                                            return <span class="uk-label uk-label-success">Visible</span>;
                                                        case '2':
                                                            return <span class="uk-label uk-label-warning">Programado</span>;
                                                        case '3':
                                                            return <span class="uk-label md-bg-blue-grey-700">oculto</span>;
                                                    }
                                                })()}
                                            </p>
                                            <p><img src={`${config.connectionString}${item.img}`} alt="" /></p>
                                            <p>
                                                <Link className="md-btn md-btn-primary" to={`/admin/banners_detail/${item.bannerId}`}>+ info</Link>
                                            </p>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                    <p>No hay data.</p>
                                )}
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-new" data-uk-modal>
                <div className="uk-modal-dialog uk-margin-auto-vertical">
                    <div className="uk-modal-header">
                        <h2 className="uk-modal-title">Nuevo Banner</h2>
                    </div>
                    <div className="uk-modal-body uk-grid-small" data-uk-grid>
                        <div className="uk-width-1-1">
                            <div className="md-input-wrapper md-input-wrapper-outlined">
                                <label>Titulo</label>
                                <input type="text" name='title' className="md-input" onChange={handleChange} required value={newBannerInfo.title} />
                            </div>
                        </div>
                        <div className="uk-width-1-1">
                            <Slim
                                ratio="2:1"
                                /* minSize={{ width: 600, height: 400 }} */
                                label="Haga click para subir una imagen"
                                instantEdit="true"
                                didConfirm={didConfirmSlim}
                            >
                                <input type="file" name="foo" />
                            </Slim>
                        </div>
                        <div className="md-radio md-radio-inline">
                            <input id="3" type="radio" name="visible" onChange={handleChangeRadio} checked={newBannerInfo.visible === "1"} value={"1"} />
                            <label for="3">Visible</label>
                        </div>
                        <div className="md-radio md-radio-inline">
                            <input id="radio_program" type="radio" name="visible" onChange={handleChangeRadio} checked={newBannerInfo.visible === "2"} value={"2"} />
                            <label for="radio_program">Programado</label>
                        </div>
                        <div className="md-radio md-radio-inline">
                            <input id="radio_program_oculto" type="radio" name="visible" onChange={handleChangeRadio} checked={newBannerInfo.visible === "3" ? true : false} value={"3"} />
                            <label for="radio_program_oculto">Oculto</label>
                        </div>
                        {newBannerInfo.visible == "2" &&
                            <>
                                <div className="uk-width-1-1">
                                    <div className="md-input-wrapper md-input-wrapper-outlined md-input-filled">
                                        <label>Fecha Inicio</label>
                                        <input type="date" name='dateFrom' className="md-input" onChange={handleChange} required value={newBannerInfo.dateFrom} />
                                    </div>
                                </div>
                                <div className="uk-width-1-1">
                                    <div className="md-input-wrapper md-input-wrapper-outlined md-input-filled ">
                                        <label>Fecha Fin</label>
                                        <input type="date" name='dateTo' className="md-input" onChange={handleChange} required value={newBannerInfo.dateTo} />
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    <div className="uk-modal-footer uk-text-right">
                        <button className="md-btn md-btn-text uk-modal-close" type="button">Cancelar</button>
                        <button onClick={UploadDocument} className="md-btn md-color-white md-btn-primary">Crear Banner</button>
                    </div>
                </div>
            </div>
            <div className="md-fab-wrapper">
                <a data-uk-toggle="target: #modal-new" className="md-fab md-fab-primary md-fab-wave-light" href="javascript:void(0)"><i className="material-icons">add</i></a>
            </div>
        </>
    );
}
export default Products;