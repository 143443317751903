import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import Logo from '../../assets/img/main_logo.png'

import { useHistory } from 'react-router-dom';
const Header = () => {
    let history = useHistory();
    return (
        <div>
            <div data-uk-sticky >
                <nav className="uk-navbar-container uk-navbar md-bg-blue-grey-900" uk-navbar="mode: click;">
                    <div className="uk-navbar-left">
                        <button onClick={() => history.goBack()} className="md-btn md-btn-primary md-btn-outlined uk-margin-left md-color-white"><i className='material-icons md-color-white'>keyboard_arrow_left</i> Regresar</button>
                    </div>
                    <div className="uk-navbar-center">
                        <a className="uk-navbar-item uk-logo" href="#"><img src={Logo} width="180px" /></a>
                    </div>

                </nav>
            </div>

        </div>
    );
}

export default Header;
