import React, { useEffect } from 'react';
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import Home from "./views/website/home";
import AdminPanel from './views/portal/AdminPanel';
import LoginPage from './views/portal/LoginPage';
import Terms from './views/website/terms';
import Preguntas from './views/website/preguntas';
import ClientForm from './views/website/ClientForm';
import { HashLink as Link } from 'react-router-hash-link';

import UserActions from './providers/user/user.actions';
import './App.css'

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';//,faDownload,faLinkedIn
import PaymentScreen from './views/website/PaymentScreen';
import PaymentScreenDone from './views/website/PaymentScreenDone';
import PaymentScreenDecline from './views/website/PaymentScreenDecline';
library.add(fab);

function App() {

  const user = UserActions();
  useEffect(() => {
    /* user.setCurrentUser({ rol: 1 }); */
    console.log();
  }, [])
  return (
    <div className="App">
      <HashRouter>
        <div id="offcanvas-overlay" data-uk-offcanvas="overlay: true">
          <div class="uk-offcanvas-bar">

            <button class="uk-offcanvas-close" type="button" data-uk-close></button>


            <h3><Link smooth to="./#inicio-section">Inicio</Link></h3>
            <h3><Link smooth to="./#servicios-section">Servicios</Link></h3>
            <h3><Link smooth to="./#nosotros-section">Nosotros</Link></h3>
            <h3><Link smooth to="./#planes-section">Programas</Link></h3>
            <h3><Link smooth to="./preguntas">Preguntas</Link></h3>

          </div>
        </div>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/terms' component={Terms} />
          <Route exact path='/preguntas' component={Preguntas} />
          <Route exact path='/payment' component={PaymentScreen} />
          <Route exact path='/paymentdone' component={PaymentScreenDone} />
          <Route exact path='/paymentdecline' component={PaymentScreenDecline} />
          <Route exact path='/form/:code' component={ClientForm} />
          <Route path='/admin' render={() =>
            user.currentUser ? (
              <AdminPanel />
            ) : (
                <Redirect to='/login' />
              )
          } />
          <Route path='/login' render={() =>
            user.currentUser ? (
              <Redirect to='/admin/clients' />
            ) : (
                <LoginPage />
              )
          } />

        </Switch>
      </HashRouter>
    </div>
  );
}

export default App;
