import React, { useState, useEffect } from 'react';
import Cards from 'react-credit-cards';
import moment from 'moment';
import $ from 'jquery';
import { Link, useHistory } from 'react-router-dom'

import UIkit from 'uikit';
import MDUIkit from 'md-uikit';

import Cleave from 'cleave.js/react';
import Header from './header_cart';
import Footer from './footer';

import DoneImg from "../../assets/img/thank-you-icon.gif"
import Logo from "../../assets/img/main_logo.png"
import cards from "../../assets/img/cards.JPG"
import PCILogo from "../../assets/img/pci-logo.png"
import verifySSL from "../../assets/img/verify_ssl.png"

import CartActions from '../../providers/cart/cart.actions';
import UserActions from '../../providers/user/user.actions';

import 'react-credit-cards/lib/styles.scss';
import { config } from '../../settings.js';

const PaymentScreen = () => {
    const [cartInfo, setCartInfo] = useState([]);
    const [personalDetails, setPersonalDetails] = useState({
        lastname: '',
        name: '',
        email: ''
    });
    const [selectedMethod, setSelectedMethod] = useState({
        method: "",
        status: "pending"
    });
    const [checks, setChecks] = useState({
        terms: 'N',
        privacy: 'N'
    });
    const [promoCodeDetails, setPromoCodeDetails] = useState({
        code: "",
        valid: false
    });
    const [totalValues, setTotalValues] = useState(0);
    const [paymentDone, setPaymentDone] = useState(false);

    const cart = CartActions();
    const user = UserActions();
    const history = useHistory();

    useEffect(() => {
        MDUIkit.components();
        setCartInfo(cart.cartItems);
        if (cart.cartItems.alt_price != "0.00") {
            setTotalValues({ total: cart.cartItems.alt_price, subTotal: cart.cartItems.alt_price });
        } else {
            setTotalValues({ total: cart.cartItems.price, subTotal: cart.cartItems.price });
        }
        console.log();
        $('#btn_pagar').on('click', function (e) {
            e.preventDefault();
            // Crea el objeto Token con Culqi JS
            console.log("creating token");
            window.Culqi.createToken();
        });
    }, []);
    async function validateVoucher() {
        var valid = false;
        var notification = "Código no valido";

        const button = document.querySelector("#btn_agregar_voucher");
        let standardValue = button.innerHTML;
        button.innerHTML = '<div data-uk-spinner></div> Validando...';
        button.disabled = true;
        try {
            const req = {
                "code": promoCodeDetails.code
            };
            console.log(JSON.stringify(req));

            const response = await fetch(`${config.connectionString}/api/voucher_read_by_code.php`, {
                method: 'POST',
                body: JSON.stringify(req)
            });
            console.log("sendInfo");
            console.log(JSON.stringify(req));

            if (response.status >= 400 && response.status < 500) {
                //modal.hide();
                setPromoCodeDetails({ ...promoCodeDetails, notification: notification, valid: valid });
                throw "Error" + response.status;
            }
            if (response.status >= 500 && response.status < 600) {
                //modal.hide();
                UIkit.notification('Error. Please try later.');
            }
            const res = await response.json();
            console.log(res);
            var discount = "";
            if (res.active == "1") {
                if (res.type_discount == "Porcentaje") {
                    discount = res.amount + "%";
                    var new_total = totalValues.subTotal - (totalValues.subTotal * (res.amount / 100));
                    setTotalValues({ ...totalValues, total: new_total.toFixed(2) });
                } else {
                    discount = "S/. " + res.amount;
                    var new_total = totalValues.subTotal - res.amount;
                    setTotalValues({ ...totalValues, total: new_total.toFixed(2) });
                }
                if (res.type_duration != "Indefinido") {
                    const hoy = moment();
                    if (hoy <= moment(res.valid_date)) {
                        valid = true;
                        notification = "Código valido";
                    } else {
                        notification = "Código vencido";
                    }
                } else {
                    valid = true;
                    notification = "Código valido";
                }
                setPromoCodeDetails({ ...promoCodeDetails, valid: valid, notification: notification, amount: discount, voucherId: res.voucherId });
            } else {
                notification = "Código inactivo";
                setPromoCodeDetails({ ...promoCodeDetails, valid: valid, notification: notification });
            }

            button.innerHTML = standardValue;
            button.disabled = false;
        } catch (error) {
            button.innerHTML = standardValue;
            button.disabled = false;
            console.log(error);
        }
    }
    async function sendInfo() {
        const button = document.querySelector("#btn_enviar");
        let standardValue = button.innerHTML;
        button.innerHTML = '<div data-uk-spinner></div> Cargando...';
        button.disabled = true;
        try {
            const req = {
                "productId": cart.cartItems.productId,
                "voucherId": promoCodeDetails.voucherId,
                "name": personalDetails.name,
                "lastname": personalDetails.lastname,
                "email": personalDetails.email,
                "subtotal": totalValues.subTotal,
                "total": totalValues.total,
            };
            console.log("sendInfo");
            console.log(JSON.stringify(req));

            const response = await fetch(`${config.connectionString}/api/order_insert.php`, {
                method: 'POST',
                body: JSON.stringify(req)
            });
            if (response.status >= 400 && response.status < 500) {
                UIkit.notification('Information error.');
                //modal.hide();
                throw "Error" + response.status;
            }
            if (response.status >= 500 && response.status < 600) {
                //modal.hide();
                UIkit.notification('Error. Please try later.');
            }
            const res = await response.json();
            console.log(res);
            setPaymentDone(true);
            button.innerHTML = standardValue;
            button.disabled = false;
        } catch (error) {
            button.innerHTML = standardValue;
            button.disabled = false;
            console.log(error);
        }
    }
    const handleChange = event => {
        event.preventDefault();
        const { value, name } = event.target;
        setPersonalDetails({ ...personalDetails, [name]: value });
        validateFullDetailsComplete();
    }
    const handleChangeVoucher = event => {
        event.preventDefault();
        const { value, name } = event.target;
        setPromoCodeDetails({ ...promoCodeDetails, [name]: value });
    }
    const handleInputRadio = (e) => {
        const { value } = e.target;
        setSelectedMethod({ ...selectedMethod, method: value })
    }
    const openCart = (event) => {
        console.log("test");
        event.preventDefault();
        var valorTotal = parseFloat(totalValues.total).toFixed(2);
        valorTotal = valorTotal.replace('.', '');
        console.log(valorTotal);
        user.setCurrentUser({
            amount: totalValues.total,
            name: personalDetails.name,
            lastname: personalDetails.lastname,
            email: personalDetails.email,
        });
        window.otherDetails = {
            "productId": cart.cartItems.productId,
            "voucherId": promoCodeDetails.voucherId,
            "name": personalDetails.name,
            "lastname": personalDetails.lastname,
            "email": personalDetails.email,
            "subtotal": totalValues.subTotal,
            "total": totalValues.total,
            "url": config.connectionString,
        };
        window.Culqi.options({
            lang: 'es',
            style: {
                logo: 'https://mat-training.com/main_logo.png',
                maincolor: '#1B4DE2',
                buttontext: '#ffffff',
                maintext: '#263238',
                desctext: '#4A4A4A'
            }
        });
        window.Culqi.settings({
            title: "MAT - Training PERU",
            currency: "PEN",
            description: cartInfo.name,
            amount: valorTotal,
            order: Date.now().toString(),
        });
        if (checks.terms == "Y") {
            console.log("abriendo Culqi");
            window.Culqi.open();
            /*  sendInfo(); */
        } else {
            UIkit.notification("Por favor acepte los términos y condiciones", "info");
        }
    }
    function validateFullDetailsComplete() {
        let flagWhites = false;
        if (selectedMethod.method == "") {
            return;
        }
        if (selectedMethod.method == "card") {
            if (personalDetails.name == "" || personalDetails.lastname == "" || personalDetails.email == "") {
                flagWhites = true;
            }
        }
        if (selectedMethod.method == "efectivo") {

        }
        if (!flagWhites) {
            setSelectedMethod({ ...selectedMethod, status: "complete" });
        } else {
            setSelectedMethod({ ...selectedMethod, status: "pending" });
        }
    }
    const onClickAddVoucher = (event) => {
        var notification;
        if (promoCodeDetails.code == "") {
            notification = "Campo obligatorio";
        } else {
            validateVoucher();
        }
        setPromoCodeDetails({ ...promoCodeDetails, notification: notification });
    }
    const CheckContinue = (event) => {
        let { id, value } = event.target;
        setChecks({ ...checks, [id]: value });
        if (value == "N") {
            setSelectedMethod({ ...selectedMethod, status: "pending" });
        } else {
            validateFullDetailsComplete();
        }
    }

    return (
        !paymentDone ? (
            <>
                <Header />
                <div className="uk-container uk-padding">
                    <div data-uk-grid>
                        <div className="uk-width-2-5@m" >
                            <div className="uk-card">
                                <div className="uk-card-header border-bottom md-bg-blue-grey-900">
                                    <h3 className="uk-card-title uk-margin-remove-bottom uk-text-left">
                                        <div className="uk-clearfix">
                                            <div className="uk-float-left">
                                                <h3 className="md-color-white">Tu orden:</h3>
                                            </div>
                                            {/* <div className="uk-float-right">
                                            <small className="md-color-white">Total</small>
                                            {cartInfo.alt_price != "0.00" ?
                                            <h4 className='uk-margin-remove md-color-white uk-text-bold'>S/. {cartInfo.alt_price}</h4>
                                            :
                                            <h4 className='uk-margin-remove md-color-white uk-text-bold'>S/. {cartInfo.price}</h4>
                                            }
                                        </div> */}
                                        </div>
                                    </h3>
                                </div>
                                <div className="uk-card-body">
                                    <div className="uk-grid" data-uk-grid>
                                        <div className="uk-width-1-1" align="center">
                                            <div className="uk-card cascade-card-narrower">
                                                <div class="uk-card-media-top cascade-card-overlay md-bg-white">
                                                    {cartInfo.image != "default" ?
                                                        <img src={`${config.connectionString}${cartInfo.image}`} alt="" />
                                                        :
                                                        <div className="gradient_image_header_1" style={{ height: "250" }} />
                                                    }

                                                </div>
                                                <div class="cascade-card-content uk-card-body">
                                                    <h5>{cartInfo.name}</h5>
                                                    <div dangerouslySetInnerHTML={{ __html: cartInfo.description }}></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='uk-width-1-1'>
                                            <div data-uk-grid className="uk-grid-small">
                                                <div className='uk-width-2-3@m'>
                                                    <div class="md-input-wrapper md-input-wrapper-helper-text ">
                                                        <label>Ingrese un gift card/ promo code</label>
                                                        <input type="text" name="code" class="md-input" onChange={handleChangeVoucher} />
                                                        <span class="md-input-bar"></span>
                                                        {promoCodeDetails.valid ?
                                                            <p className="md-color-green-500">{promoCodeDetails.notification}</p>
                                                            :
                                                            <p className="md-color-red-500">{promoCodeDetails.notification}</p>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='uk-width-1-3@m'>
                                                    <button id="btn_agregar_voucher" onClick={onClickAddVoucher} className="md-btn md-color-white md-btn-primary">Agregar</button>

                                                </div>
                                            </div>
                                        </div>
                                        <div className='uk-width-1-1'>
                                            <hr />
                                            <table width="100%" >
                                                <tbody>
                                                    <tr>
                                                        <td className='uk-text-left'>Subtotal</td>
                                                        <td className='uk-text-right'>
                                                            <p className='md-color-grey-600 uk-margin-remove'><strong>S/. {totalValues.subTotal}</strong></p>
                                                        </td>
                                                    </tr>
                                                    {promoCodeDetails.valid &&
                                                        <tr>
                                                            <td className='uk-text-left'>Descuento</td>
                                                            <td className='uk-text-right'>{promoCodeDetails.amount}</td>
                                                        </tr>
                                                    }
                                                    <tr>
                                                        <td className='uk-text-left' ><h5>TOTAL</h5>  </td>
                                                        <td className='uk-text-right' ><h5>S/. {totalValues.total}</h5></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="uk-width-3-5@m ">
                            <h3 className="md-color-white">Seleccione un metodo de pago:</h3>
                            <div className="uk-card  uk-card-body">
                                <div data-uk-grid className="uk-grid-small">
                                    <div className="uk-width-1-1">
                                        <div className={selectedMethod.method === "card" ? "selected_card uk-card" : " uk-card"} >
                                            <div className="uk-card-header">
                                                <div className="md-radio">
                                                    <input id="radio_card" type="radio" onChange={handleInputRadio} checked={selectedMethod.method === "card"} value={"card"} />
                                                    <label htmlFor="radio_card"><h4>Tarjeta de credito / debito</h4></label>
                                                </div>
                                            </div>
                                            {selectedMethod.method === "card" &&
                                                <div className="uk-card-body">
                                                    <div className="uk-grid" data-uk-grid>
                                                        <div className="uk-width-1-1">
                                                            <div className="uk-grid-small" data-uk-grid>
                                                                <div className="uk-width-1-2">
                                                                    <div class="md-input-wrapper md-input-filled">
                                                                        <label>Nombres</label>
                                                                        <input type="text" name='name' class="md-input" size="50" onChange={handleChange} required value={personalDetails.name} />

                                                                    </div>
                                                                </div>
                                                                <div className="uk-width-1-2">
                                                                    <div class="md-input-wrapper md-input-filled">
                                                                        <label>Apellidos</label>
                                                                        <input type="text" name='lastname' class="md-input" size="50" onChange={handleChange} required value={personalDetails.lastname} />

                                                                    </div>
                                                                </div>
                                                                <div className="uk-width-1-1">
                                                                    <div class="md-input-wrapper md-input-filled">
                                                                        <label>Correo</label>
                                                                        <input type="text" name='email' class="md-input" size="50" onChange={handleChange} required value={personalDetails.email} />

                                                                    </div>
                                                                </div>
                                                                <div className='uk-width-1-1'>
                                                                    <div className="md-checkbox">
                                                                        <input id="terms" type="checkbox" value={checks.terms == "Y" ? "N" : "Y"} checked={checks.terms == "Y" ? true : false} onChange={CheckContinue} />
                                                                        <label htmlFor="terms">Acepto los <a target='_blank' href="https://mat-training.com/#/terms">Términos y condiciones</a></label>
                                                                    </div>
                                                                </div>
                                                                <div className="uk-width-1-1" align="center">
                                                                    <p>Aceptamos todas las tarjetas</p>
                                                                    <p><img src={cards} alt="" /></p>
                                                                </div>
                                                                <div className='uk-width-1-1'>
                                                                    {selectedMethod.status == "complete" ?
                                                                        <button /* id="btn_pagar" */ onClick={openCart} className="md-btn md-color-white md-btn-primary md-btn-block  md-btn-large">
                                                                            <i className="material-icons">verified_user</i> Pagar con tarjeta
                                                </button>
                                                                        :
                                                                        <button className="md-btn disabled md-btn-block md-btn-large" disabled><i className="material-icons">verified_user</i> Enviar pago</button>
                                                                    }
                                                                    <p>Pago asegurado</p>
                                                                    <span><img src={PCILogo} alt="" width="100px" />
                                                                        <img src={verifySSL} alt="" width="100px" /></span>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="uk-width-1-1" >
                                        <div className={selectedMethod.method === "efectivo" ? "selected_card uk-card" : " uk-card"} >
                                            <div className="uk-card-header">
                                                <div className="md-radio">
                                                    <input id="radio_efectivo" type="radio" onChange={handleInputRadio} checked={selectedMethod.method === "efectivo"} value={"efectivo"} />
                                                    <label htmlFor="radio_efectivo"><h4>Pago Link</h4></label>
                                                </div>
                                            </div>
                                            {selectedMethod.method === "efectivo" &&
                                                <div className="uk-card-body">
                                                    <div className="uk-padding">
                                                        No disponible
                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        ) : (
                <>
                    < div className="uk-container">
                        <div className="uk-padding">

                            <div className="uk-card uk-card-body" align="center">
                                <img src={Logo} width="180px" />
                                <h3 className="md-color-green-400">¡ Pago completado !</h3>
                                <img width='50px' src={DoneImg} alt="" />
                                <h5>{personalDetails.name + " " + personalDetails.lastname}</h5>
                                <h5>S/. {totalValues.total}</h5>
                                <p>Hemos recibido tu pedido.</p>
                                <p>Pronto recibirás un correo a <strong>{personalDetails.email}</strong> con los siguientes pasos.</p>
                                <Link to="./" className="md-btn md-btn-outlined md-btn-primary md-btn-wave-light uk-margin-right">
                                    ir a la pagina principal</Link>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </>
            )

    );
}

export default PaymentScreen;
