import React, { useState, useEffect } from 'react';

import UIkit from 'uikit';
import axios from 'axios';
import MDUIkit from 'md-uikit';
import Slim from '../../assets/slim/slim.react';
import { useParams, useHistory } from "react-router-dom";



import UserActions from '../../providers/user/user.actions';
import { config } from '../../settings.js';

const Products = () => {
    const [bannerInfo, setBannerInfo] = useState([]);
    const [showSlim, setShowSlim] = useState(false);
    const [sizeExceeded, setSizeExceeded] = useState(false);
    const [selectedFile, setSelectedFile] = useState([]);
    let { id } = useParams();

    const user = UserActions();
    let history = useHistory();
    const handleChange = event => {
        event.preventDefault();
        const { value, name } = event.target;
        console.log(name);
        console.log(name + " " + value);
        setBannerInfo({ ...bannerInfo, [name]: value });
    }

    const handleChangeRadio = event => {
        const { value } = event.target;
        console.log(value);
        setBannerInfo({ ...bannerInfo, visible: value });

    }

    async function fetchBannerInfo() {

        try {
            const req = {
                "id": id,
                "jwt": user.currentUser.token
            };
            const response = await fetch(`${config.connectionString}/api/banner_read_by_id.php`, {
                method: 'POST',
                body: JSON.stringify(req)
            });
            if (response.status >= 400 && response.status < 500) {
                UIkit.notification('Information error.');
                throw "Error" + response.status;
            }
            if (response.status >= 500 && response.status < 600) {
                UIkit.notification('Error. Please try later.');
            }
            const res = await response.json();

            setBannerInfo(res);
            /* modal.hide(); */
            console.log(res);
        } catch (error) {
            /*  modal.hide(); */
            console.log(error);
        }
    }
    useEffect(() => {
        MDUIkit.components();
        console.log("banner : ");
        console.log("banner : " + id);
        fetchBannerInfo();
    }, []);

    async function sendInfo(newObj) {
        try {
            const req = {
                "bannerId": id,
                "title": newObj.title,
                "img": newObj.img,
                "visible": newObj.visible,
                "dateFrom": newObj.dateFrom,
                "dateTo": newObj.dateTo,
                "jwt": user.currentUser.token
            };
            console.log("sendInfo");
            console.log(JSON.stringify(req));
            var modal = UIkit.modal.dialog('<div className=\'uk-modal-body\'><p>Cargando..</p></div>', { 'bgClose': false, 'escClose': false });

            const response = await fetch(`${config.connectionString}/api/banner_update.php`, {
                method: 'POST',
                body: JSON.stringify(req)
            });
            if (response.status >= 400 && response.status < 500) {
                UIkit.notification('Information error.');
                //modal.hide();
                throw "Error" + response.status;
            }
            if (response.status >= 500 && response.status < 600) {
                //modal.hide();
                UIkit.notification('Error. Please try later.');
            }
            const res = await response.json();
            modal.hide();
            console.log(res);
            UIkit.notification("Información actualizada!", "success");
        } catch (error) {
            modal.hide();
            console.log(error);
        }
    }
    const deleteBanner = (e) => {
        e.preventDefault();

        if (window.confirm('Estas Seguro?')) {
            sendDelete();
        }
    }
    async function sendDelete() {
        try {
            const req = {
                "bannerId": id,
                "jwt": user.currentUser.token
            };
            console.log("sendInfo");
            console.log(JSON.stringify(req));
            var modal = UIkit.modal.dialog('<div className=\'uk-modal-body\'><p>Cargando..</p></div>', { 'bgClose': false, 'escClose': false });

            const response = await fetch(`${config.connectionString}/api/banner_delete.php`, {
                method: 'POST',
                body: JSON.stringify(req)
            });
            if (response.status >= 400 && response.status < 500) {
                UIkit.notification('Information error.');
                //modal.hide();
                throw "Error" + response.status;
            }
            if (response.status >= 500 && response.status < 600) {
                //modal.hide();
                UIkit.notification('Error. Please try later.');
            }
            const res = await response.json();
            history.push('../banners');
            modal.hide();
            console.log(res);
            UIkit.notification("Información actualizada!", "success");
        } catch (error) {
            modal.hide();
            console.log(error);
        }
    }
    const onChangeSlim = (event) => {
        event.preventDefault();
        console.log('fileChangedHandler');
        if (event.target.files[0]) {
            let flag = true;
            if (event.target.files[0].size > 3145728) { // 3mb
                flag = false
                setSizeExceeded(true);
            }
            if (flag) {

                setSizeExceeded(false);
                setSelectedFile(event.target.files);
            } else {
                UIkit.notification('This file does not meet the conditions.');
                setSelectedFile([]);

            }
        }
    }
    const UploadDocument = event => {
        event.preventDefault();
        let flagWhites = true;
        let flagGeneral = true;
        if (bannerInfo.title === "") {
            flagWhites = false;
        }
        if (!flagWhites) {
            flagGeneral = false;
            UIkit.notification('Complete toda la información requerida.', 'danger');
        }
        if (showSlim && selectedFile.length == 0) {
            flagGeneral = false;
            UIkit.notification("Please select a file", "danger");
        }
        console.log(flagGeneral);
        if (flagGeneral) {
            if (showSlim) {
                uploadHandler();
            } else {
                sendInfo(bannerInfo);
            }
        }
    }
    const uploadHandler = () => {
        const formData = new FormData();

        console.log("Uploading file...");
        console.log(selectedFile);
        let fileName = selectedFile.name;
        let fileContent = selectedFile.file;
        formData.append('code', "123");
        formData.append(
            'myFile_0',
            fileContent,
            fileName
        );
        axios.post(`${config.connectionString}/api/script_insertImage.php`, formData, {
            onUploadProgress: progressEvent => {
                console.log(progressEvent.loaded / progressEvent.total)
            }
        })
            .then(response => {
                console.log(response);
                const newObj = { ...bannerInfo };
                newObj.img = response.data.url;
                //setBannerInfo({ ...bannerInfo, img: response.data.url });

                sendInfo(newObj);

            }).catch(function (error) {
                console.log(error);
            });
    }
    const didConfirmSlim = (data) => {
        // slim instance reference
        console.log(data);
        console.log(data.output.image);
        var canvas = data.output.image;
        var fileName = data.input.name;
        canvas.toBlob(function (blob) {
            setSelectedFile({ file: blob, name: fileName });
        });
    }

    console.log(selectedFile);
    return (
        <>
        <div className="content-before"></div>
            <h4 className="text-on-app-bar">Editar Banner</h4>
            {bannerInfo.title ?
                <div className="uk-card uk-card-body">
                    <div className=" uk-grid-small" data-uk-grid>
                        <div className="uk-width-1-1">
                            <div className="md-input-wrapper md-input-wrapper-outlined md-input-filled">
                                <label>Titulo</label>
                                <input type="text" name='title' className="md-input" onChange={handleChange} required value={bannerInfo.title} />
                            </div>
                        </div>
                        <div className="uk-width-1-1">
                            {showSlim ?
                                <>
                                    <p>
                                        <Slim
                                            ratio="2:1"
                                            /* minSize={{ width: 600, height: 400 }} */
                                            label="Haga click para subir una imagen"
                                            /* size="200,150" */
                                            instantEdit="true"
                                            didConfirm={didConfirmSlim} >
                                            <input type="file" name="foo" />
                                        </Slim>
                                    </p>

                                    <button className="md-btn md-btn-primary ripple-surface" onClick={() => setShowSlim(false)}>Cancelar</button>

                                </> :
                                <>
                                    <p><img src={`${config.connectionString}${bannerInfo.img}`} alt="" /></p>
                                    <button className="md-btn md-btn-primary ripple-surface" onClick={() => setShowSlim(true)}>Cambiar banner</button>
                                </>
                            }


                        </div>
                        <div className="md-radio md-radio-inline">
                            <input id="3" type="radio" name="visible" onChange={handleChangeRadio} checked={bannerInfo.visible === "1"} value={"1"} />
                            <label htmlFor="3">Visible</label>
                        </div>
                        <div className="md-radio md-radio-inline">
                            <input id="radio_program" type="radio" name="visible" onChange={handleChangeRadio} checked={bannerInfo.visible === "2"} value={"2"} />
                            <label htmlFor="radio_program">Programado</label>
                        </div>
                        <div className="md-radio md-radio-inline">
                            <input id="radio_program_oculto" type="radio" name="visible" onChange={handleChangeRadio} checked={bannerInfo.visible === "3" ? true : false} value={"3"} />
                            <label htmlFor="radio_program_oculto">Oculto</label>
                        </div>
                        {bannerInfo.visible == "2" &&
                            <>
                                <div className="uk-width-1-1">
                                    <div className="md-input-wrapper md-input-wrapper-outlined md-input-filled">
                                        <label>Fecha Inicio</label>
                                        <input type="date" name='dateFrom' className="md-input" onChange={handleChange} required value={bannerInfo.dateFrom} />
                                    </div>
                                </div>
                                <div className="uk-width-1-1">
                                    <div className="md-input-wrapper md-input-wrapper-outlined md-input-filled ">
                                        <label>Fecha Fin</label>
                                        <input type="date" name='dateTo' className="md-input" onChange={handleChange} required value={bannerInfo.dateTo} />
                                    </div>
                                </div>
                            </>
                        }
                        <div className="uk-width-1-1" >
                            <p align="right">
                                <button onClick={deleteBanner} className="md-btn md-color-white md-bg-red-800 ripple-surface">Eliminar banner</button>
                                <button onClick={UploadDocument} className="md-btn md-color-white md-bg-purple-800 ripple-surface">Guardar Información</button>
                            </p>
                        </div>
                    </div>
                </div>
                : <p>Cargando...</p>
            }
        </>
    );
}
export default Products;