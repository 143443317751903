import React, { useState, useEffect } from 'react';

import UIkit from 'uikit';
import axios from 'axios';
import MDUIkit from 'md-uikit';
import Slim from '../../assets/slim/slim.react';
import { useParams, useHistory } from "react-router-dom";


import DoneImg from "../../assets/img/done.png"
import Logo from "../../assets/img/main_logo.png";

import UserActions from '../../providers/user/user.actions';
import {config} from '../../settings.js';

const Products = () => {
    const [clientInfo, setClientInfo] = useState([]);
    const [selectedFile, setSelectedFile] = useState([]);
    const [isLoading, SetIsLoading] = useState(true);

    const user = UserActions();
    let history = useHistory();
    let { code } = useParams();

    const handleChange = event => {
        event.preventDefault();
        const { value, name } = event.target;
        setClientInfo({ ...clientInfo, [name]: value });
    }

    async function fetchClientInfo() {

        try {
            const req = {
                "code": code
            };
            const response = await fetch(`${config.connectionString}/api/person_read_by_code.php`, {
                method: 'POST',
                body: JSON.stringify(req)
            });
            if (response.status >= 400 && response.status < 500) {
                //UIkit.notification('Information error.');
                throw "Error" + response.status;
            }
            if (response.status >= 500 && response.status < 600) {
                UIkit.notification('Error. Please try later.');
            }
            const res = await response.json();

            SetIsLoading(false);
            setClientInfo(res);
            /* modal.hide(); */
            console.log(res);
        } catch (error) {
            SetIsLoading(false);
            /*  modal.hide(); */
            console.log(error);
        }
    }
    useEffect(() => {
        MDUIkit.components();
        console.log("banner : ");
        fetchClientInfo();
    }, []);

    async function sendInfo(newObj) {
        try {
            const req = {
                "id": clientInfo.id,
                "dni": clientInfo.dni,
                "firstname": clientInfo.firstname,
                "lastname": clientInfo.lastname,
                "email": clientInfo.email,
                "phone": clientInfo.phone,
                "profileImg": clientInfo.profileImg,
                "dob": clientInfo.dob,
                "activity": clientInfo.activity,
                "diet_no": clientInfo.diet_no,
                "diet_yes": clientInfo.diet_yes,
                "fullday": clientInfo.fullday,
                "size": clientInfo.size,
                "weight": clientInfo.weight
            };
            console.log("sendInfo");
            console.log(JSON.stringify(req));
            var modal = UIkit.modal.dialog('<div className=\'uk-modal-body\'><p>Cargando..</p></div>', { 'bgClose': false, 'escClose': false });

            const response = await fetch(`${config.connectionString}/api/person_update.php`, {
                method: 'POST',
                body: JSON.stringify(req)
            });
            if (response.status >= 400 && response.status < 500) {
                UIkit.notification('Information error.');
                modal.hide();
                throw "Error" + response.status;
            }
            if (response.status >= 500 && response.status < 600) {
                modal.hide();
                UIkit.notification('Error. Please try later.');
            }
            const res = await response.json();
            modal.hide();
            console.log(res);
            UIkit.modal("#modal-done", { 'bgClose': false, 'escClose': false }).show();
        } catch (error) {
            modal.hide();
            console.log(error);
        }
    }
    const onClickUpdatePerson = () => {
        let flagWhites = true;
        let flagGeneral = true;
        if (clientInfo.dni === "") {
            flagWhites = false;
        }
        if (!flagWhites) {
            flagGeneral = false;
            UIkit.notification('Complete toda la información requerida.', 'danger');
        }
        if (flagGeneral) {
            sendInfo();
        }
    }

    const didConfirmSlim = (data) => {
        // slim instance reference
        console.log(data);
        console.log(data.output.image);
        var canvas = data.output.image;
        var fileName = data.input.name;
        canvas.toBlob(function (blob) {
            setSelectedFile({ file: blob, name: fileName });
        });
    }

    const UploadDocument = event => {
        if (selectedFile.file == undefined) {
            UIkit.notification("Please select a file", "danger");
        } else {
            uploadHandler();
        }
    }
    const CloseFileManager = event => {
        event.preventDefault();
        setSelectedFile([]);
        document.getElementById("input_filemanager").value = "";
        UIkit.modal("#modal-filemanager").hide();
    }
    const uploadHandler = () => {
        const formData = new FormData();

        console.log("Uploading file...");
        let fileName = selectedFile.name;
        let fileContent = selectedFile.file;
        if (clientInfo.dni) {
            formData.append('name', "profile_" + clientInfo.dni);
        }
        formData.append(
            'myFile_0',
            fileContent,
            fileName
        );
        axios.post(`${config.connectionString}/api/script_insertImage.php`, formData, {
            onUploadProgress: progressEvent => {
                console.log(progressEvent.loaded / progressEvent.total)
            }
        })
            .then(response => {
                console.log(response.data);
                document.getElementById("input_filemanager").value = "";
                UIkit.modal("#modal-filemanager").hide();
                if (response.data.url) {
                    setClientInfo({ ...clientInfo, profileImg: response.data.url });
                    UIkit.notification('Carga completa.', { status: 'success' });
                }
                else {
                    UIkit.notification('Error al cargar.');
                }

            }).catch(function (error) {
                console.log(error);
            });
    }
    const OpenFileManager = event => {
        console.log("OpenFileManager");
        //setSelectedDocument({ ...selectedDocument, Category: Category, DocID: DocID, DocName: DocName, type: list_name });

        UIkit.modal("#modal-filemanager", { 'bgClose': false, 'escClose': false }).show();
    }

    console.log(clientInfo);

    return (
        <div className="uk-grid-match uk-grid-small uk-padding-small" data-uk-height-viewport data-uk-grid>
            <div className="uk-width-1-6 uk-visible@s"></div>
            <div className="uk-width-2-3@s ">
                {isLoading ?
                    <h4>Cargando..</h4>
                    :
                    (
                        clientInfo.dni ?
                            (
                                <>
                                    <div className="uk-card uk-card-body">
                                        <div className=" uk-grid-small" data-uk-grid>
                                            <div className="uk-width-1-1" align="center">
                                                <img src={Logo} alt="" width="280px" />
                                            </div>
                                            <div className="uk-width-1-1">
                                                <h3 align="center">Bienvenido 👋</h3>
                                                <p>De ante mano agradecemos hayas confiado en nosotros. Estamos seguros que juntos cumpliremos las metas que quieres obtener.</p>
                                                
                                                <h4>Ficha de Inscripción</h4>
                                                <p>Porfavor complete la información solicitada.</p>
                                            </div>
                                            <div className="uk-width-1-1">
                                                <div className="md-input-wrapper md-input-wrapper-outlined md-input-filled">
                                                    <label>DNI</label>
                                                    <input type="text" name='dni' className="md-input" onChange={handleChange} required value={clientInfo.dni} />
                                                </div>
                                            </div>
                                            <div className="uk-width-1-2">
                                                <div className="md-input-wrapper md-input-wrapper-outlined md-input-filled">
                                                    <label>Nombres</label>
                                                    <input type="text" name='firstname' className="md-input" onChange={handleChange} required value={clientInfo.firstname} />
                                                </div>
                                            </div>
                                            <div className="uk-width-1-2">
                                                <div className="md-input-wrapper md-input-wrapper-outlined md-input-filled ">
                                                    <label>Apellidos</label>
                                                    <input type="text" name='lastname' className="md-input" onChange={handleChange} required value={clientInfo.lastname} />
                                                </div>
                                            </div>
                                            <div className="uk-width-1-2">
                                                <div className="md-input-wrapper md-input-wrapper-outlined md-input-filled ">
                                                    <label>Email</label>
                                                    <input type="email" name='email' className="md-input" onChange={handleChange} required value={clientInfo.email} />
                                                </div>
                                            </div>
                                            <div className="uk-width-1-2">
                                                <div className="md-input-wrapper md-input-wrapper-outlined md-input-filled ">
                                                    <label>Celular</label>
                                                    <input type="number" name='phone' className="md-input" onChange={handleChange} required value={clientInfo.phone} />
                                                </div>
                                            </div>
                                            <div className="uk-width-1-1">
                                                <div className="md-input-wrapper md-input-wrapper-outlined md-input-filled ">
                                                    <label>Fecha de Nacimiento</label>
                                                    <input type="date" name='dob' className="md-input" onChange={handleChange} required value={clientInfo.dob} />
                                                </div>
                                            </div>
                                            <div className="uk-width-1-2">
                                                <div className="md-input-wrapper md-input-wrapper-outlined md-input-filled ">
                                                    <label>Talla (CM)</label>
                                                    <input type="text" name='size' className="md-input" onChange={handleChange} required value={clientInfo.size} />
                                                </div>
                                            </div>
                                            <div className="uk-width-1-2">
                                                <div className="md-input-wrapper md-input-wrapper-outlined md-input-filled ">
                                                    <label>Peso en ayunas (KG)</label>
                                                    <input type="text" name='weight' className="md-input" onChange={handleChange} required value={clientInfo.weight} />
                                                </div>
                                            </div>
                                            <div className="uk-width-1-1">
                                                <div className="uk-form-row md-input-wrapper md-input-textarea md-input-filled ">
                                                    <label>Actividad Fisica Actual</label>
                                                    <textarea className="md-input" name='activity' onChange={handleChange} required value={clientInfo.activity}></textarea>
                                                </div>
                                            </div>
                                            <div className="uk-width-1-1">
                                                <div className="uk-form-row md-input-wrapper md-input-textarea md-input-filled ">
                                                    <label>¿Como es tu día a día?</label>
                                                    <textarea className="md-input" name='fullday' onChange={handleChange} required value={clientInfo.fullday}></textarea>
                                                </div>
                                            </div>
                                            <div className="uk-width-1-1">
                                                <div className="uk-form-row md-input-wrapper md-input-textarea md-input-filled ">
                                                    <label>¿Que alimentos te gustaría incluir en tu dieta?</label>
                                                    <textarea className="md-input" name='diet_yes' onChange={handleChange} required value={clientInfo.diet_yes}></textarea>
                                                </div>
                                            </div>

                                            <div className="uk-width-1-1">
                                                <div className="uk-form-row md-input-wrapper md-input-textarea md-input-filled ">
                                                    <label>¿Que alimentos NO te gustaría incluir en tu dieta?</label>
                                                    <textarea className="md-input" name='diet_no' onChange={handleChange} required value={clientInfo.diet_no}></textarea>
                                                </div>
                                            </div>
                                            <div className="uk-width-1-1">
                                                <div className="uk-card uk-card-body">
                                                    <p>Foto cuerpo completo</p>
                                                    <p align="center"><img width="50%" src={`${config.connectionString}${clientInfo.profileImg}`} alt="" /></p>
                                                    <button className='md-btn md-btn-secondary' onClick={OpenFileManager}>Cargar Foto</button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="uk-width-1-1 uk-padding uk-padding-remove-right" >
                                        <p align="right">
                                            <button onClick={onClickUpdatePerson} className="md-btn md-btn-primary ripple-surface">Enviar Información</button>
                                        </p>
                                    </div>
                                </>
                            ) : (

                                <div className="uk-card md-bg-white uk-card-body uk-position-center uk-margin-small">
                                    <img className="uk-align-center" src={Logo} width='220px' alt='' />
                                    <h3>Lo sentimos</h3>
                                    <p>Este codigo no existe.</p>
                                </div>
                            )
                    )}
            </div>
            <div className="uk-width-1-6 uk-visible@s">
            </div>
            <div id="modal-done" data-uk-modal>
                <div className="uk-modal-dialog uk-margin-auto-vertical">
                    <div className="uk-modal-header">
                        <h2 className="uk-modal-title">Listo!</h2>
                    </div>
                    <div className="uk-modal-body uk-text-center">
                        <img width='50px' src={DoneImg} alt="" />
                        <p>Tu informacion ha sido enviada correctamente</p>
                        <small>Puedes cerrar esta ventana</small>
                    </div>
                    <div className="uk-modal-footer uk-text-right">
                    </div>
                </div>
            </div>
            <div id="modal-filemanager" data-uk-modal>
                <div className="uk-modal-dialog uk-margin-auto-vertical">
                    <div className='uk-float-right'>
                        <a onClick={CloseFileManager} ><i className="material-icons md-icon">close</i></a>
                    </div>

                    <div className="uk-modal-header">
                        <h2 className="uk-modal-title">Cargar Imagen</h2>
                    </div>
                    <div className="uk-modal-body">
                        <p><strong>IMPORTANTE:</strong> LAS FOTOS DEBERAN SER DE FRENTE Y NO SELFIE EN UN LUGAR DONDE TENGA BASTANTE LUZ</p>
                        <ul className="uk-list">
                            <li><strong>HOMBRES:</strong> Sin polo y en short</li>
                            <li><strong>MUJERES:</strong> En top y short</li>
                        </ul>
                        <div data-uk-grid align="center">
                            <div className="uk-width-1-4"></div>
                            <div className="uk-width-1-2">
                                <Slim
                                    ratio="3:5"
                                    label="Haga click para subir una imagen"
                                    instantEdit="true"
                                    didConfirm={didConfirmSlim} >
                                    <input id="input_filemanager" type="file" name="foo" />
                                </Slim>
                            </div>
                        </div>
                    </div>
                    <div className="uk-modal-footer uk-text-right">
                        <button className="md-btn md-btn-text uk-modal-close" type="button" onClick={CloseFileManager}>Cancelar</button>
                        <a className="md-btn md-btn-primary" onClick={UploadDocument}>Subir foto</a>
                    </div>
                </div>
            </div>
        </div >
    );
}
export default Products;