export const config = {
    connectionString: /* 'http://localhost/mat_training' */ 'https://mat-training.com/backend',
    main_url: /* 'http://localhost:3000/' */ 'https://mat-training.com'


}
export const settings = {
    language: {
        data: {
            admin: {
                logout: {
                    ES: "Cerrar Sesión",
                    EN: "Log out",
                },
                pages: {
                    vouchers: {
                        page_name: {
                            ES: "Vouchers",
                            EN: "Vouchers",
                        },
                        add_new_text: {
                            ES: "Presione (+) para crear un voucher.",
                            EN: "Press (+) to create a new voucher.",
                        },
                        edit_text: {
                            ES: "Presione (+) para guardar la información.",
                            EN: "Press (+) to save.",
                        },
                        new_modal: {
                            title: {
                                ES: "Nuevo voucher",
                                EN: "New voucher"
                            },
                            fields: {
                                code: {
                                    ES: "Codigo de Voucher",
                                    EN: "Voucher code"
                                },
                                amount: {
                                    ES: "Cantidad",
                                    EN: "Amount"
                                },
                                type_discount: {
                                    ES: "Tipo de descuento",
                                    EN: "Discount type",
                                },
                                valid_date: {
                                    ES: "Valido hasta",
                                    EN: "Valid until"
                                },
                                type_duration: {
                                    ES: "Duración",
                                    EN: "Duration"
                                },
                                active: {
                                    ES: "Activo",
                                    EN: "Active"
                                },
                            }
                        }
                    },
                    packages: {
                        page_name: {
                            ES: "Paquetes",
                            EN: "Packeges",
                        },
                        add_new_text: {
                            ES: "Presione (+) para crear un voucher.",
                            EN: "Press (+) to create a new voucher.",
                        },
                        new_modal: {
                            title: {
                                ES: "Nuevo voucher",
                                EN: "New voucher"
                            },
                            fields: {
                                code: {
                                    ES: "Codigo de Voucher",
                                    EN: "Voucher code"
                                },
                                amount: {
                                    ES: "Cantidad",
                                    EN: "Amount"
                                },
                                type_discount: {
                                    ES: "Tipo de descuento",
                                    EN: "Discount type",
                                },
                                valid_date: {
                                    ES: "Valido hasta",
                                    EN: "Valid until"
                                },
                                type_duration: {
                                    ES: "Duración",
                                    EN: "Duration"
                                },
                                active: {
                                    ES: "Activo",
                                    EN: "Active"
                                },
                            }
                        }
                    },
                    billing: {
                        page_name: {
                            ES: "Facturación",
                            EN: "Billing",
                        },
                        table: {
                            fields: {
                                productName: {
                                    ES: "Nombre del Paquete",
                                    EN: "Package Name"
                                },
                                voucherCode: {
                                    ES: "Codigo del Voucher",
                                    EN: "Voucher Code"
                                },
                                total: {
                                    ES: "Total",
                                    EN: "Total",
                                },
                                name: {
                                    ES: "Nombre del Cliente",
                                    EN: "Client Name"
                                },
                                email: {
                                    ES: "Email",
                                    EN: "Email"
                                },
                                subtotal: {
                                    ES: "Sub total",
                                    EN: "Sub total"
                                },
                                created_date: {
                                    ES: "Fecha de Registro",
                                    EN: "Registry Date"
                                },
                            }
                        }
                    }
                },
            }
        }
    },
    colors: {
        primary: "md-bg-purple-400",
        secondary: "md-bg-deep-purple-900",
        primary_text: "md-color-white",
    }
}
export default settings;