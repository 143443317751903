import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom'

import BGLogin from '../../assets/img/testimonials_bg.jpg'
import Footer from './footer';
import Header from './header';
const Preguntas = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <Header />
            <div className="uk-width-1-1">
                <div class="uk-background-cover uk-panel uk-flex uk-flex-center uk-flex-middle" style={{ backgroundImage: `url(${BGLogin})` }}>
                    <div className="uk-padding">
                        <h2 className="md-color-white">Preguntas frecuentes</h2>

                    </div>
                </div>
            </div>
            <div className="uk-section">
                <div className="uk-container">
                    <ul data-uk-accordion>
                        <li>
                            <a class="uk-accordion-title" href="#"><h5 className="uk-margin-remove-bottom">¿Cómo compro un plan?</h5></a>
                            <div class="uk-accordion-content">Es muy fácil, te diriges al menú y haz clic a planes. En esta página podrás elegir el
                            plan que más se acomode a tus necesidades. Luego, haz clic en “ver más” y entra
                            a la página del plan que te interesa. Revisa toda la información necesaria y
                            finalmente haz clic en el botón “comprar” y comenzará el proceso de pago online.
                            Una vez finalizado el pago tu membresía quedará activada automáticamente.
</div>
                        </li>

                        <li>
                            <a class="uk-accordion-title" href="#"><h5 className="uk-margin-remove-bottom">¿Necesito implementos deportivos obligatorios para dar inicio a la asesoría?</h5></a>
                            <div class="uk-accordion-content">Nosotros recomendados ciertos kids, en lo que cabe la posibilidad de cada usuario
                            obtenerlos, nos adaptamos en estructurar el entrenamiento acorde a sus
                            condiciones y accesorios con los que pueda contar en plan lifestyle, mat coaching
vip y mat coaching Premium.</div>
                        </li>
                        <li>
                            <a class="uk-accordion-title" href="#"><h5 className="uk-margin-remove-bottom">¿Cómo hago uso de mi programa de nutrición?</h5></a>
                            <div class="uk-accordion-content">Al momento de hacer la compra, le llegará un usuario con contraseña para que
                            pueda acceder a la plataforma y pasar un test previo, así poder estructurar su dieta
                            personalizada, después de pasar el test se le hará llegar por mail o en la misma
plataforma.</div>
                        </li>
                        <li>
                            <a class="uk-accordion-title" href="#"><h5 className="uk-margin-remove-bottom">¿Cómo hago uso de mi plan?</h5></a>
                            <div class="uk-accordion-content">Al momento de hacer la compra, le llegará un usuario con contraseña para que
                            pueda acceder a la plataforma y pasar un test previo, así poder estructurar su dieta
                            personalizada (home program, lifestyle program, mat coaching vip y mat coaching
                            premium)
                            En el caso del programa lifestyle y mat coaching vip será un test mas amplio para
                            estructurar su programa de entrenamiento.
                            En el caso de mat coaching Premium se organiza la cita directamente via
whatsapp con el equipo individualmente.</div>
                        </li>
                        <li>
                            <a class="uk-accordion-title" href="#"><h5 className="uk-margin-remove-bottom">¿Los pagos se renuevan automáticamente al cumplir el mes?</h5></a>
                            <div class="uk-accordion-content">No, los pagos no son automatizados. Debes volver a la página de tu plan y
                            renovarlo cuando lo creas conveniente. Puedes renovar tu plan en el siguiente
link:</div>
                        </li>
                        <li>
                            <a class="uk-accordion-title" href="#"><h5 className="uk-margin-remove-bottom">¿Cómo puedo cancelar?</h5></a>
                            <div class="uk-accordion-content">Los planes no se cancelan. Tiene una fecha de vencimiento, y después de eso puedes no renovarlo.</div>
                        </li>
                        <li>
                            <a class="uk-accordion-title" href="#"><h5 className="uk-margin-remove-bottom">¿Hay devoluciones en los pagos de membresías?</h5></a>
                            <div class="uk-accordion-content">No hay devoluciones en los pagos por membresía, con excepción a que por error humano o de sistema se hayan registrado un doble pago.</div>
                        </li>
                        <li>
                            <a class="uk-accordion-title" href="#"><h5 className="uk-margin-remove-bottom">¿Cómo accedo a las clases via zoom?</h5></a>
                            <div class="uk-accordion-content">Muy fácil, será añadido a un grupo de whatsapp donde 5 min previos al inicio de
cada sesión se les mandará un link de acceso.
En caso tener el programa mat coaching vip y mat coaching premium se le enviara
a su whatsapp directamente.
NOTA: El home program será guiado por nuestro director Michael Talavera.</div>
                        </li>
                        <li>
                            <a class="uk-accordion-title" href="#"><h5 className="uk-margin-remove-bottom">¿Hay horarios en las clases den vivo?</h5></a>
                            <div class="uk-accordion-content">Los horarios estarán públicos en el inicio de nuestra web y deberá elegir uno via
correo o whatsapp al momento de adquirirlo.</div>
                        </li>

                        <li>
                            <a class="uk-accordion-title" href="#"><h5 className="uk-margin-remove-bottom">¿Habrá clase los feriados?</h5></a>
                            <div class="uk-accordion-content">No tendremos clases los días feriados. Respetaremos el descanso correspondiente en los días festivos. Cualquier cambio extraordinario en el calendario lo comunicaremos con la debida anticipación.</div>
                        </li>
                        <li>
                            <a class="uk-accordion-title" href="#"><h5 className="uk-margin-remove-bottom">¿Cuál es la diferencia entre las MAT COACHING VIP Y MAT COACHING
PREMIUM?</h5></a>
                            <div class="uk-accordion-content">El plan mat coaching Premium, tiene cita con el dr , nutricionista y head coach
virtual y de ser el caso tuviese una lesión seria presencial.</div>
                        </li>
                        <li>
                            <a class="uk-accordion-title" href="#"><h5 className="uk-margin-remove-bottom">¿Puedo cambiar mi reserva?</h5></a>
                            <div class="uk-accordion-content">Al momento de consentir el horario no hay cambio.</div>
                        </li>
                        <li>
                            <a class="uk-accordion-title" href="#"><h5 className="uk-margin-remove-bottom">¿Cómo obtendré mi plan personalizado de ejercicios y nutrición?</h5></a>
                            <div class="uk-accordion-content">Será enviado vía correo electrónico.</div>
                        </li>
                        <li>
                            <a class="uk-accordion-title" href="#"><h5 className="uk-margin-remove-bottom">¿Cómo compro y reservo un asesoramiento 1 a 1 con Michael coach?</h5></a>
                            <div class="uk-accordion-content">Antes de adquirir dicho plan deberá consultar via correo y whatsapp la
disponibilidad de horario, ya que es limitado.</div>
                        </li>
                        <li>
                            <a class="uk-accordion-title" href="#"><h5 className="uk-margin-remove-bottom">¿Hay devoluciones en los servicios de reserva?</h5></a>
                            <div class="uk-accordion-content">No hay devoluciones en los pagos por reserva, con excepción a que por error humano o de sistema se hayan registrado un doble pago.</div>
                        </li>
                        <li>
                            <a class="uk-accordion-title" href="#"><h5 className="uk-margin-remove-bottom">¿Puedo cambiar el horario de mi reserva?</h5></a>
                            <div class="uk-accordion-content">Se pueden gestionar cambios únicamente siempre y cuando se informe con 48
horas de anticipación previa evaluación a disponibilidad.
Toda gestión por correo.
</div>
                        </li>
                        <li>
                            <a class="uk-accordion-title" href="#"><h5 className="uk-margin-remove-bottom">¿Cómo me contacto con ustedes?</h5></a>
                            <div class="uk-accordion-content">Nos puedes escribir a <a href="mailto:info@mat-training.com">info@mat-training.com</a></div>
                        </li>
                        <li>
                            <a class="uk-accordion-title" href="#"><h5 className="uk-margin-remove-bottom">¿Qué es una masterclass y cómo asisto?</h5></a>
                            <div class="uk-accordion-content">Es una clase especial sobre: deporte, fitness, salud o bienestar. Dictada por un especialista en el tema. No es necesario registrarse. Teniendo un plan mat Avanzado o mat Élite tienes acceso a las materclass se le enviara un link de acceso previo a ello.</div>
                        </li>
                    </ul>

                </div>
            </div>

            <Footer />
        </div>
    );
}

export default Preguntas;
