import React, { Component } from 'react';
import { Route } from "react-router-dom";
/* import UIkit from 'uikit'; */

import 'uikit/dist/css/uikit.min.css';
import Routes from './routes.js';
/* Custom Routes */
import BannersInner from "./BannersInner.js";
import ClientsInner from "./ClientsInner.js";
import ProductInner from "./ProductInner.js";
import VoucherInner from "./VoucherInner.js";

const MainContent = () => {
  return (
    <main id="admin-main">
      <div className='uk-container uk-text-left'>
        {/* Menu Routes */}
        {Routes.map((route, key) =>
          <div key={key}>
            <Route path={route.path} component={route.component} />
            {route.submenu &&
              route.submenu.map((inner_route, key) =>
                <Route path={inner_route.path} component={inner_route.component} />
              )
            }
          </div>
        )}
        {/* Custom Routes */}
        <Route exact path='/admin/banners_detail/:id' component={BannersInner} />
        <Route exact path='/admin/client_detail/:id' component={ClientsInner} />
        <Route exact path='/admin/product_detail/:id' component={ProductInner} />
        <Route exact path='/admin/vouchers_detail/:id' component={VoucherInner} />
      </div>
    </main>
  )
}

export default MainContent;