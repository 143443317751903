
import UserProvider from "./user/user.provider";
import CartProvider from "./cart/cart.provider";
import React from 'react';

const Provider = ({ children }) => {
    return (

        <UserProvider>
            <CartProvider>
                {children}
            </CartProvider>
        </UserProvider>
    );
}
export default Provider;