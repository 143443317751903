import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import UIkit from 'uikit';
import MDUIkit from 'md-uikit';

import settings from '../../settings.js';

import DoneImg from "../../assets/img/done.png"

const Footer = () => {
  const [newSubscriber, setNewSubscriber] = useState({
    name: "",
    price: "",
    alt_price: "",
    description: "",
    image: ""
  });

  useEffect(() => {
    MDUIkit.components();
  }, []);

  async function sendInfo() {
    const button = document.querySelector("#btn_enviar");
    let standardValue = button.innerHTML;
    button.innerHTML = '<div data-uk-spinner></div> Cargando...';
    button.disabled = true;
    try {
      const req = {
        "name": newSubscriber.name,
        "email": newSubscriber.email
      };
      console.log("sendInfo");
      console.log(JSON.stringify(req));
      
      const response = await fetch(`${settings.connectionString}/api/subscriber_insert_update.php`, {
        method: 'POST',
        body: JSON.stringify(req)
      });
      if (response.status >= 400 && response.status < 500) {
        UIkit.notification('Information error.');
        //modal.hide();
        throw "Error" + response.status;
      }
      if (response.status >= 500 && response.status < 600) {
        //modal.hide();
        UIkit.notification('Error. Please try later.');
      }
      const res = await response.json();
      UIkit.modal("#modal-done", { 'bgClose': false, 'escClose': false }).show();
      console.log(res);
      button.innerHTML = standardValue;
      button.disabled = false;
    } catch (error) {
      button.innerHTML = standardValue;
      button.disabled = false;
      console.log(error);
    }
  }
  const handleChange = event => {
    event.preventDefault();
    const { value, name } = event.target;
    setNewSubscriber({ ...newSubscriber, [name]: value });
  }

  const onClickCreateProduct = event => {
    event.preventDefault();
    let flagWhites = true;
    let flagGeneral = true;
    if (newSubscriber.name === "" || newSubscriber.email === "") {
      flagWhites = false;
    }
    if (!flagWhites) {
      flagGeneral = false;
      UIkit.notification('Complete toda la información requerida.');
    }
    console.log(flagGeneral);
    if (flagGeneral) {
      sendInfo();
    }
  }
  return (
    <>
      <div className="uk-section-secondary uk-light">
        <div className="uk-padding-small uk-container">
          <div data-uk-grid>
            <div className='uk-width-1-2@s'>
              <h3 className="primary-text">Siguenos</h3>
              <p>Para aquellos que se toman en serio tener más, hacer más, dar más y ser más, y creen que el exito llega con esfuerzo.</p>
              <div data-uk-grid>
                <div className="uk-width-1-6">
                  <div align="center">
                    <a href="https://www.facebook.com/MAT-Training-Center-569682809797835/">
                      <FontAwesomeIcon className='md-color-white fa-2x ' icon={['fab', 'facebook']} />
                    </a>
                  </div>
                </div>
                <div className="uk-width-1-6">
                  <div align="center">
                    <a href="https://www.instagram.com/mat.training/"><FontAwesomeIcon className='md-color-white fa-2x ' icon={['fab', 'instagram']} /></a>
                  </div>
                </div>
                <div className="uk-width-1-6">
                  <div align="center">
                    <a href="https://wa.me/51959315818/?text=Hola,%20te%20escribo%20desde%20la%20web"><FontAwesomeIcon className='md-color-white fa-2x ' icon={['fab', 'whatsapp']} /></a>
                  </div>
                </div>
              </div>
              {/* <div align='center'>
                <a href="https://www.facebook.com/CorretajeBienesRaicesMonicaTejadaD"><i className='fab fa-facebook-f'></i><span> Monica Tejada</span></a><br />
                <a href="https://wa.me/51959672804/?text=Hola,%20te%20escribo%20desde%20la%20web"><i className='fab fa-whatsapp'></i> <span> 959672804 - 959386208</span></a>
              </div> */}
            </div>
            <div className='uk-width-1-2@s'>

              <h3 className="primary-text">Suscribete</h3>
              <p>Registrate para recibir un promociones y ofertas exclusivas.</p>
              <p><button className="md-btn md-btn-primary" data-uk-toggle="target: #modal-subscriber" >Lo quiero</button></p>
            </div>
          </div>
        </div>
      </div>
      <div id="footer" className="uk-section-secondary uk-light">
        <div className="uk-padding-small">
          <div className='uk-grid'>
            <div className='uk-width-1-2@s uk-width-3-4@m'>
              <div className="uk-child-width-1-2@m" data-uk-grid>
                <div align='left'>
                  <p>©Copyright 2020 MAT-Training - All Rights Reserved</p>
                </div>
              </div>
            </div>
            <div className='uk-width-1-2@s uk-width-1-4@m'>
              <div align='right'>
                <Link to='./terms'>Terminos y condiciones</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="modal-done" data-uk-modal>
        <div className="uk-modal-dialog uk-margin-auto-vertical">
          <div className="uk-modal-header">
          <h3 className="primary-text">Listo!</h3>
          </div>
          <div className="uk-modal-body uk-text-center">
            <img width='50px' src={DoneImg} alt="" />
            <p>Tu información ha sido enviada correctamente. En breve te enviaremos tu rutina y dieta gratis</p>
          </div>
          <div className="uk-modal-footer uk-text-right">
            <button class="md-btn md-btn-primary uk-modal-close" type="button">Cerrar</button>
          </div>
        </div>
      </div>
      <div id="modal-subscriber" data-uk-modal>
        <div className="uk-modal-dialog uk-margin-auto-vertical">
          <div className="uk-modal-header">
            <h3>Subscribirse</h3>
          </div>
          <div className="uk-modal-body uk-grid-small" data-uk-grid>
            <p>Registrate para recibir promociones y ofertas exclusivas</p>
            <div class="uk-width-1-1">
              <div class="md-input-wrapper md-input-wrapper-outlined">
                <label>Nombre</label>
                <input type="text" name='name' class="md-input" onChange={handleChange} required value={newSubscriber.name} />
              </div>
            </div>
            <div class="uk-width-1-1">
              <div class="md-input-wrapper md-input-wrapper-outlined ">
                <label>Email</label>
                <input type="email" name='email' class="md-input" onChange={handleChange} required value={newSubscriber.email} />
              </div>
            </div>
          </div>
          <div class="uk-modal-footer uk-text-right">
            <button class="md-btn md-btn-text uk-modal-close" type="button">Cancelar</button>
            <button id="btn_enviar" onClick={onClickCreateProduct} className="md-btn md-color-white md-btn-primary">Quiero mi rutina y dieta</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
